// Junk Drawer
// CSS moved from codewise skin

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Password strength */

.confirm-parent,
.password-parent {
	width: 100%;
}

div.password-strength {
	margin: -6px 29px 0px 0px;
}

div.password-confirm {
	margin: 0px 29px 0px 0px;
}

div.password-confirm span {
	float: right;
}

div.form-item div.password-suggestions.description {
	width: 42em;
	padding: 0.7em 1em 0.4em 1em;
	border-color: #d8d8d8;
}

div.form-item div.password-suggestions.description li {
	margin-bottom: 4px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Webforms */

div.form-actions input.button-primary.webform-previous,
div.form-actions input.button-primary.webform-next {
	margin-right: 4px;
}

input.form-autocomplete {
	background-position: 98% center !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Print media styles */
@media print {

	#memberOutreachMembersBlurb02 {
		display: block !important;
		padding: 0px;
	}

	.first-paragraph {
		border: 1px solid #cccccc;
	}

}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Date picker calendar styles */

#calroot {
	z-index: 10001;
	width: 265px;
	margin-top: -2px;
	padding: 2px;
	font-size: 12px;
	background: #ffffff no-repeat 0px 0px none;
	border: 1px solid #b8b8b8;
	border-top: 0px solid #b8b8b8;
	-moz-border-radius: 0px 3px 3px 3px;
	-webkit-border-radius: 0px 3px 3px 3px;
	border-radius: 0px 3px 3px 3px;
}

#calhead {
	height: 22px;
	padding: 2px 0px;
}

#caltitle {
	float: left;
	margin: 0px 0px 0px 8px;
	padding: 2px 9px;
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	line-height: 20px;
	-moz-text-shadow: 0px 1px 0px #d8d8d8;
	-webkit-text-shadow: 0px 1px 0px #d8d8d8;
	text-shadow: 0px 1px 0px #d8d8d8;
}

#calnext, #calprev {
	display: block;
	opacity: 0.67;
	float: left;
	width: 13px;
	height: 21px;
	margin: 8px 0px 0px 4px;
	background: no-repeat -43px -602px none; /* Will cause a JavaStrip error! */
	cursor: pointer;
}

#calprev:hover {
	opacity: 1;
}

#calnext {
	float: right;
	margin: 8px 4px 0px 0px;
	background-position: -63px -602px; /* Will cause a JavaStrip error! */
}

#calnext:hover {
	opacity: 1;
}

#calprev.caldisabled, #calnext.caldisabled {
	visibility: hidden;
}

/* year/month selector */
#caltitle select {
	margin-right: 4px;
	margin-bottom: 2px;
	font-size: 13px;
}

/* body */
#calbody {
	clear: both;
	padding: 5px;
}

/* names of the days */
#caldays {
	height: 24px;
	overflow: visible;
	border-bottom: 1px solid #b8b8b8;
}

#caldays span {
	display: block;
	float: left;
	width: 35px;
	text-align: center;
}

/* container for weeks */
#calweeks {
	margin-top: 4px;
	background-color: #ffffff;
}

/* single week */
.calweek {
	clear: left;
	height: 22px;
}

/* single day */
.calweek a {
	display: block;
	float: left;
	width: 34px;
	height: 24px;
	margin-left: 1px;
	text-decoration: none;
	font-size: 12px;
	text-align: center;
	line-height: 24px;
	color: #666666;
}

/* different states */
.calweek a:hover, .calfocus {
	background-color: #d8d8d8;
}

/* sunday */
a.calsun {
	color: #ff0000;
}

/* offmonth day */
a.caloff {
	color: #aaaaaa;
}

a.caloff:hover {
	background-color: #f5f5fa;
}

/* unselecteble day */
a.caldisabled {
	background-color: #efefef !important;
	color: #cccccc !important;
	cursor: default;
}

/* today */
#caltoday {
	color: #ffffff;
	background-color: #0091c0;
	border: 1px solid #005c87;
}

/* currently selected day */
#calcurrent {
	color: #ffffff;
	background-color: #939393;
	border: 1px solid #7f7f7f;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Horizontal rules */

hr.darker, hr.gray {
	color: #808080;
	background-color: #808080;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Images */

.imgResponsive, .img-responsive,
.responsiveImg, .responsive-img,
img.responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

img.fullWidth {
	width: 100%;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Calendar */

#calendarContainerOuter {
	display: none;
	width: 100%;
	text-align: center;
	margin: -17px 0px 0px 0px;
	background: #e5e5e5;
}

body.mobileVersion #calendarContainerOuter {
	margin: 0px 0px 10px 0px;
	margin-bottom: 0px;
}

#calendarContainerOuter #calendarContainerInner {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	max-width: 1188px;
	overflow: auto;
	margin: 0px auto;
	padding: 0px;
	text-align: left;
}

body.mobileVersion #calendarContainerOuter #calendarContainerInner {
	min-width: 272px;
}

#contentContainerOuter #calendarContainerOuter #calendarContainerInner #calendar {
	display: block;
}

#calendar {
	min-height: 360px;
}

body.mobileVersion #calendar {
	min-height: 10px;
}

#calendar #calendarLeftColumn {
	float: left;
	margin-top: 10px;
	width: 45%;
}

body.mobileVersion #calendar #calendarLeftColumn {
	float: none;
	width: auto;
	margin-top: 0px;
}

#calendarContainer table,
#calendarContainer thead,
#calendarContainer tbody,
#calendarContainer tr {
	display: block;
}

#calendarContainer th {
	display: inline-block;
}

#calendarContainer td {
	display: inline-block;
	min-width: 14.2857142857%;
}

body.mobileVersion #calendarLoading {
	float: right;
	margin-top: -15px;
	padding: 0px 10px 0px 0px;
	text-align: right;
	color: #808080;
}

#calendar #calendarRightColumn {
	float: right;
	width: 50%;
	margin-top: 64px;
}

body.mobileVersion #calendar #calendarRightColumn {
	float: none;
	width: auto;
	margin: 18px 0px -6px 0px;
	padding: 0px 5px;
}

body.desktopVersion #calendar #calendarRightColumn div.tabbed {
	margin-right: 7px;
}

body.desktopVersion #calendar #calendarRightColumn div.tabbed div.tabbedContent {
	margin-right: 7px;
}

#calendar #calendarRightColumn div.tabbed div.tabbedContent div.tab {
	min-height: 174px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Calendar */

.rollingCalendar .day {
	color: #b8b8b8;
	background-color: #ffffff;
	border: 1px solid #ffffff;
}

.rollingCalendar .currentMonth {
	color: #808080;
	background: #ebeff0;
	border: 1px solid #d9d9d9;
}

.rollingCalendar .day.currentMonth {
	color: #808080;
}

.rollingCalendar .today {
	background-color: #0091c0;
	border: 1px solid #00386d;
	color: #ffffff !important;
}

.rollingCalendar .hasEvent {
	background-color: #005c87;
	border: 1px solid #00386d;
}

.rollingCalendar .today.hasEvent {
	background: #0091c0;
	background: -webkit-gradient(linear, 0 0, 0 bottom, from(#0091c0), to(#005c87));
	background: -webkit-linear-gradient(#0091c0 0%, #005c87 75%);
	background: -moz-linear-gradient(#0091c0 0%, #005c87 75%);
	background: -ms-linear-gradient(#0091c0 0%, #005c87 75%);
	background: -o-linear-gradient(#0091c0 0%, #005c87 75%);
	background: linear-gradient(#0091c0 0%, #005c87 75%);
	border: 1px solid #005c87;
	border-bottom-color: #00386d;
	-moz-border-image: -moz-linear-gradient(top, #005c87 0%, #00386d 75%);
	-webkit-border-image: -webkit-linear-gradient(top, #005c87 0%, #00386d 75%);
	border-image: linear-gradient(to bottombottom, #005c87 0%, #00386d 75%);
	border-image-slice: 1;
}

div.rollingCalendar {
	margin: 0px 0px 10px 0px;
}

div.rollingCalendar.fullCalendar {
	width: 100%;
	min-height: 272px;
	margin-bottom: 0px;
}

div.rollingCalendar.greyGradient {
	background: none;
}

body.mobileVersion div.rollingCalendar {
	clear: left;
}

div.rollingCalendar table {
	width: 100%;
	margin: 0px;
	border: 0px solid #ffffff;
	border-collapse: collapse;
}

body.mobileVersion div.rollingCalendar table {
	margin-bottom: 7px;
}

body.mobileVersion div.rollingCalendar.fullCalendar table {
	min-width: 310px;
}

div.rollingCalendar.fullCalendar table tbody tr.titleRow {
	width: 100%;
}

body.mobileVersion div.rollingCalendar.fullCalendar table tbody tr.titleRow {
	height: 39px;
	text-align: center;
}

div.rollingCalendar.fullCalendar table tbody tr.titleRow th {
	max-width: 70%;
	font-weight: normal;
	text-align: center;
}

body.mobileVersion div.rollingCalendar.fullCalendar table tbody tr.titleRow th {
	width: 20%;
	min-width: 20%;
}

body.mobileVersion div.rollingCalendar.fullCalendar table tbody tr.titleRow th[colspan="5"] {
	width: auto;
}

div.rollingCalendar table tbody tr td {
	margin: 0px;
	padding: 0px;
}

div.rollingCalendar.fullCalendar table tbody tr td {
	width: 14%;
}

div.rollingCalendar table tbody tr td div.day > a,
div.rollingCalendar table tbody tr td div.day > a:link {
	color: #ffffff;
}

div.rollingCalendar table tbody tr td div {
	margin: 0px 0px 3px 3px;
	padding: 4px 0px 3px 0px;
	text-align: center;
	font-size: 13px;
	line-height: 1.4em;
}

div.rollingCalendar table tbody tr td:first-child div {
	margin-left: 0px;
}

div.rollingCalendar.fullCalendar table tbody tr td div {
	padding: 12px 0px;
	font-size: 12px;
}

body.mobileVersion div.rollingCalendar.fullCalendar table tbody tr td div {
	padding: 8px 0px;
}

div.rollingCalendar p.rollingCalendarLegend {
	margin: 8px 0px 0px 0px;
	padding: 8px 8px 6px 6px;
	text-transform: uppercase;
	text-align: center;
	color: #333333;
	font-size: 12px !important;
	background-color: #ffffff;
	border: 1px solid #d9d9d9;
}

div.rollingCalendar p.rollingCalendarLegend span {
	display: inline-block;
	vertical-align: -5%;
	height: 10px;
	width: 10px;
	margin: 0px 3px;
	border-style: solid;
	border-width: 1px;
}

div.rollingCalendar p.rollingCalendarLegend a {
	display: inline-block;
	margin-left: 10px;
}

/* Admin menu */

// #admin-menu-wrapper {
// 	font-size: .846em;
// }

// #admin-menu .dropdown {
// 	line-height: 1.4em;
// }

// #admin-menu #admin-menu-icon.dropdown li {
// 	margin-right: 4px !important;
// }

// #admin-menu .dropdown li ul {
// 	line-height: 1.2em;
// }

// #admin-menu .dropdown a, #admin-menu .dropdown li > span {
// 	padding: 1px 8px;
// }

// #admin-menu > div > .dropdown > li > a {
// 	border-right: 0;
// 	margin-bottom: 4px;
// 	padding: 0px 10px;
// 	line-height: 20px;
// }

// #admin-menu .dropdown .admin-menu-toolbar-category a,
// #admin-menu .dropdown .admin-menu-action a {
// 	font-family: "Lucida Grande", Verdana, sans-serif;
// 	font-weight: bold;
// 	font-size: 11px !important;
// }

iframe[name="google_conversion_frame"] {
	display: none;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Classes */

.padded {
	padding: 10px;
}

.inPageContent acronym, .inPageContent button, .inPageContent blockquote, .inPageContent input, .inPageContent label, .inPageContent option, .inPageContent select, .inPageContent span.tooltip, .inPageContent textarea, .inPageContent th, .inPageContent td {
	font-size: 16px;
	color: #666666;
}

.inPageContent blockquote, .inPageContent p, .inPageContent th, .inPageContent td, .inPageContent ol, .inPageContent ul {
	 line-height: 1.6em;
	 margin-bottom: 14px;
	 margin-top: 0;
}

.inPageContent div.shortcode, .inPageContent pre.shortcode {
	margin: 0;
	padding: 0;
	white-space: normal;
}

.inPageContent div.tabbed ul.tabbedLinks {
	background-color: #e6e9ed;
}

.inPageContent div.tabbed ul.tabbedLinks li a, .inPageContent div.tabbed ul.tabbedLinks li a:visited {
	color: #00386d;
}

.inPageContent .webform-component-markup {
	max-width: 95%;
}

.inPageContent .item-list .pager, .inPageContent .simplePagerNav {
	clear: both;
	margin-top: 20px;
	text-align: right;
}

.inPageContent .item-list .pager a,
.inPageContent .simplePagerNav a {
	color: #fff;
	background-color: #0091c0;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 300;
	line-height: 1.5;
	padding: 5px 10px;
	text-decoration: none;
}

.inPageContent div.form-actions input.webform-previous.form-submit, .inPageContent div.form-actions input.webform-next.form-submit {
	color: #0091c0;
	border-radius: 3px;
	line-height: 1.5;
	margin-right: 10px;
	padding: 5px 10px;
}

.inPageContent input.form-text {
	width: 95%;
}

.inPageContent fieldset.webform-component-fieldset {
	border: 1px solid #eee;
}

.inPageContent li {
	margin-bottom: 10px;
}

.inPageContent .list-group li {
	margin-bottom: 0px;
}

#autocomplete {
	overflow: visible;
	z-index: 99999;
}

#autocomplete li {
	color: #666;
	margin-bottom: 0px;
	padding: 0 5px;
}

.inPageContent .largeText p {
	font-size: 120%;
	line-height: 1.2em;
}

.inPageContent video {
  width: 100% !important;
  height: auto !important;
}

.inPageContent .btn, .inPageContent .btn:active, .inPageContent .btn.active, .inPageContent .btn.disabled, .inPageContent .btn[disabled] {
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.inPageContent .calendar-calendar td a {
	font-weight: 300;
}

.inPageContent .emailLinkContainer {
	line-height: 20px;
	white-space: pre;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#user-login-form .captcha.form-wrapper {
	border-left: 0 !important;
}

.form-item.form-item-captcha-response label {
	display: none;
}

.form-item.form-item-captcha-response .description {
	float: right;
	width: 65%;
}

.form-item.form-item-captcha-response #edit-captcha-response, .form-item.form-item-captcha-response #edit-captcha-response--1, .form-item.form-item-captcha-response #edit-captcha-response--2, .form-item.form-item-captcha-response #edit-captcha-response--3, .form-item.form-item-captcha-response #edit-captcha-response--4 {
	width: 15%;
}

.inPageContent div.tabbed div.tabbedContent div.tab {
	width: 100%;
}

.inPageContent .messages {
	width: 97%;
}

.view-display-id-block_12 .news-item-list {
	margin-bottom: 0px;
}

#views-exposed-form-amapceo-people-view-block-20 select#edit-tid-1 {
	width: 100%;
}

#views-exposed-form-amapceo-people-view-block-27 select#edit-tid {
	width: 95%;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Classes */

// body.admin-menu #block-views-news-updates-list-block-5, body.admin-menu #block-views-news-updates-list-block-6, body.admin-menu #block-views-news-updates-list-block-7, body.admin-menu #block-views-news-updates-list-block-8, body.admin-menu #block-views-news-updates-list-block-9, body.admin-menu #block-views-news-updates-list-block-10,  body.admin-menu #block-views-news-updates-list-block-11 {
// 	border-bottom: 1px solid #eee;
// }

body.page-events-calendar .tabs.primary {
	display: none;
}

body.page-events-calendar .view-events-calendar {
	width: 98%;
}

body.page-events-calendar .view-events-calendar .view-header {
	max-height: 65px;
}

body.page-events-calendar .view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
	top: 5px;
}

body.page-events-calendar .view .date-nav-wrapper .date-prev {
	right: 70px;
}

.forgotPasswordLink {
	padding: 0 20px;
}

#myHomepageContainer {
	margin-top: 30px;
}

#myHomepageContainer h2.subColumnHeader {
	border-bottom: 4px solid #eee;
	font-size: 150%;
	margin-bottom: 5px;
	text-align: center;
}

.contactMapOverlay {
	background:transparent;
	position:relative;
	width:100%;
	height:300px; /* your iframe height */
	top:300px;  /* your iframe height */
	margin-top:-300px;  /* your iframe height */
}

#contentUpperFullWidth {
	margin-bottom: 30px;
}

#contentContainerInner .search-form {
	display: none;
}

#search-form+h2 {
	font-size: 175% !important;
	margin-bottom: 20px;
}

body.page-search .search-results .title,
body.page-search404 .search-results .title {
	font-size: 1.4em;
}

body.page-search .search-results li,
body.page-search404 .search-results li {
	border-bottom: 1px solid #ececec;
	margin-bottom: 1em !important;
}

body.page-search .search-results li .search-snippet strong,
body.page-search404 .search-results li .search-snippet strong {
	background-color: #c0ffff;
	padding: 2px;
}

div.item-list ul.pager li {
	margin: 0 !important;
	padding: 0 !important;
}

div.item-list ul.pager li.pager-current {
	color: #0091c0;
	font-size: 12px;
	font-weight: 600;
	margin: 0 6px !important;
}

#memberAnnouncementScrollarea {
	border: 1px solid #eee;
	overflow: hidden;
	overflow-y: scroll;
	padding: 10px 24px 10px 10px;
}

.announcementTitleContainer {
	margin-top: -6px;
}

.announcementDate {
	margin-top: -10px !important;
}

.userInfoPanel {
	min-height: 275px;
}

div.highslide-iframe-content {
	height: 90% !important;
}

.fileGroupSummary {
	display: inline-block;
	min-height: 50px;
}

div.fileTitle {
	margin: 0;
}

div.fileButtons {
	margin-top: 0px;
	margin-bottom: 4px;
}

div.rightAligned.previewButton {
	margin-bottom: 0px;
	margin-left: 4px;
}

.subColumn50 div.fileButtonAlignment,
.subColumn33 div.fileButtonAlignment,
.subColumn25 div.fileButtonAlignment {
	margin-left: 3px !important;
}

.subColumn50 div.fileButtons,
.subColumn33 div.fileButtons,
.subColumn25 div.fileButtons {
	margin: 0;
}

.fileButtons .btn {
	font-size: 12px;
	line-height: 1.5;
	padding: 5px 10px;
}

.fileButtonAlignment {
	float: right;
	margin-left: 24px;
	margin-bottom: 2px;
}

.filenameTitle {
	display: inline-block;
	float: left;
	margin: 2px 6px 4px 1px;
}

.fileBody {
	clear: left;
	margin-left: 24px;
	max-width: 70%;
}

.well.well-file {
	overflow: auto;
	padding: 15px;
}

#headerContainerOuter.loading .loginLink {
	display: none;
}

.section {
	margin: 0;
}

div.ExpandOMatic {
	background-color: white;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	color: #434a54;
	margin-bottom: 20px;
	min-height: 20px;
	padding: 20px 20px 14px;
}

div.ExpandOMatic div.ExpandOMaticHeading.h3 {
	margin-bottom: 10px;
}

div.ExpandOMatic div.ExpandOMaticHeading.h3 h3 {
	margin-left: 24px
}

div.ExpandOMatic div.ExpandOMaticHeading.h3 span.ExpandOMaticGraphic {
	 margin: 1px 6px 0 0;
}

div.ExpandOMaticContent .well.well-file,
div.ExpandOMaticContent .panel ul.list-group {
	border: 0;
	box-shadow: 0 0 0 transparent !important;
	margin: 0;
	padding: 0;
}

div.ExpandOMaticContent .well.well-file h3,
div.ExpandOMaticContent .panel ul.list-group h3 {
	color: #666;
	font-size: 16px;
}

div.ExpandOMaticContent .well.well-file div.fileButtons .btn-default, div.ExpandOMaticContent .panel ul.list-group div.fileButtons .btn-default {
	background-color: #fff;
	color: #666 !important;
	margin-bottom: 0px;
}

div.ExpandOMaticContent .well.well-file div.fileButtons .btn-default:hover, div.ExpandOMaticContent .panel ul.list-group div.fileButtons .btn-default:hover {
	background-color: #eee;
	color: #00386d !important;
}

div.ExpandOMaticContent .well.well-file  div.fileButtons, div.ExpandOMaticContent div.fileGroup div.panel ul.list-group li.list-group-item div.fileButtons {
	margin-top: 0px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Layout styles */

// #admin-menu,
// #admin-menu .dropdown { /* pop admin menu above site navigation */
// 	z-index: 9999;
// }

.reps-row {
	width: 25%;
}
