.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.sg-pattern {
  clear: left;
}

#icons {              // sass-lint:disable-line id-name-format
  display: flex;

  & > div {
    border: 1px solid #eeeeee;
    padding: 1em;
    text-align: center;
  }
}
