%m-callout,
.m-callout {
  display: -ms-grid;
  display: grid;
  position: relative;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: minmax(min-content, max-content) minmax(min-content, max-content);
  grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
  margin-bottom: ru(2);
  background-color: transparent;
  padding: 45px 45px 25px 45px;

  @include breakpoint($bp-type-large) {
    padding-right: 90px;
    padding-left: 90px;
  }

  @include breakpoint($bp-2col) {
    -ms-grid-columns: 1fr 45px 1fr;
    grid-template-columns: 1fr 45px 1fr;
    -ms-grid-rows: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
    align-content: end;
  }
}

.m-callout--blue {
  @extend %m-callout;
  background-color: palette('blue', 250);
}

%m-callout__prefix,
.m-callout__prefix {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 1; 
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;

  @include breakpoint($bp-2col) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 1; 
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
  }
}

.m-callout--blue__prefix {
  @extend %m-callout__prefix;
}

%m-callout__content,
.m-callout__content {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / span 1;

  @include breakpoint($bp-2col) {
    -ms-grid-column: 3; 
    -ms-grid-column-span: 1;
    grid-column: 3 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
  }
}

.m-callout--blue__content {
  @extend %m-callout__content;
}

%m-callout__actions {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-row: 3 / span 1;

  @include breakpoint($bp-2col) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 3;
    grid-column: 1 / span 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
  }
}

.m-callout--blue__title {
  margin-bottom: ru(1);
  line-height: 36px !important;
  letter-spacing: normal !important;
  color: palette(white) !important;
  font-size: 28px !important;
  font-weight: 700 !important;

  @include breakpoint($bp-type-large) {
    line-height: 42px !important;
    font-size: 36px !important;
  }

  @include breakpoint($bp-2col) {
    margin-bottom: 0;
  }

  &::after {
    display: block;
    margin-top: ru(0.5);
    background-color: palette('blue', 400);
    width: ru(2);
    height: 4px;
    content: '';
  }
}

.m-callout--blue__body {

  p {
    line-height: 26px !important;
    letter-spacing: normal !important;
    color: palette(white) !important;
    font-size: 18px !important;
    font-weight: 400 !important;
  }
}

.m-callout--blue__actions {
  @extend %m-callout__actions;
  position: relative;
  top: 45px;
}

.m-callout--blue__btn {
  float: right;
}
