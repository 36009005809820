.m-search {
  position: relative;
}

.m-search .container-inline {
  display: block;

  > div {
    display: block;
  }
}

%m-search__title,
.m-search h2 {
  // @extend %u-visually--hidden;
  margin: 0;
}

%m-search__label,
.m-search label {
  // @extend %u-visually--hidden;
  display: block;
  margin: 0;
}

.m-search .form-item {
  margin: 0;
}

.m-search .form-actions input[type='submit'] {
  position: absolute;
  top: ru(0.375);
  right: ru(0.375);
  display: block;
	opacity: 0.65;
	width: 20px;
  height: 19px;
  padding: 0;
	overflow: hidden;
  cursor: pointer;
  background-image: url('../images/_icon_sprite_01.png') !important;
  background-repeat: no-repeat !important;
  background-position: -60px -65px !important;
  background-color: transparent !important;
  border: 0;
  text-indent: 100%;

  &:hover {
    opacity: 1;
  }
}

.m-search input[type='text'] {
  width: ru(9);
  height: ru(1.5);
  padding: 0 ru(1.5) 0 ru(0.25);
	font-size: 14px;
	color: #7d7d7d;
	background: #ffffff;
	border: 1px solid #d8d8d8;
	border-radius: 3px;
}
