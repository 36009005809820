select,
select.disabled,
select[disabled="disabled"],
select[readonly="readonly"] {
  background-repeat: no-repeat !important;
  background-position: right 50% !important;
  background-image: url('../images/_select_bg_01.png') !important;
}

a.icon,
span.icon,
div.ExpandOMatic span.ExpandOMaticGraphic,
a.tooltipIcon,
#bannerContainerOuter .slick-arrow.slick-prev,
#bannerContainerOuter .slick-arrow.slick-next,
.slick-dots li button,
input[type="email"],
input.email[type="text"],
input#edit-name[type="text"],
input[type="password"],
input#edit-pass,
#memberOutreachNewsletterSignup div.signupFormInner input[type="text"], #memberOutreachNewsletterSignup div.signupFormInner input[type="email"],
#calnext,
#calprev {
  background-image: url('../images/_icon_sprite_01.png');
}

.highslide-loading {
  background-image: url('../images/loading_01.gif') !important;
}

.highslide-controls,
.highslide-controls ul,
.highslide-controls a {
  background-image: url('../images/controlbar-white.gif') !important;
}

#calroot {
  background-image: url('../images/_date_input_calendar_bg_01.gif');
}

/** IE Specific Styles Removed **/
// div.styled {
//   overflow: hidden;
// }

// hr {
//   width: 100%;
//   margin: 5px 0px 15px 0px;
// }

// input[type=radio],
// input[type=checkbox] {
//   border: none;
//   padding-right: 0px;
// }

// a.button,
// button,
// input[type=button],
// input[type=reset],
// input[type=submit] { 
//   padding: 4px 8px; 
// }

/** Webkit Specific Styles Removed **/
// #homePageColumns {
//   overflow-y: hidden;
// }

/** Chrome specific styles removed **/
// #calroot {
//   background-position: -11px 0px;
// }

/** Safari specific styles removed **/
// a.icon.calendarIcon.rightAligned {
//   margin-top: 4px;
// }

// .mm-panel.mm-hasnavbar.mm-opened {
//   margin-left: -1px;
// }

// #calroot {
//   background-position: 2px 0px;
// }

/** Mobile specific styles removed **/
// input[type="button"],
// input[type="reset"],
// input[type="submit"],
// input[type="text"],
// input[type="email"],
// input[type="password"],
// textarea {
//   -webkit-border-radius: 0px 0px 0px 0px;
//   border-radius: 0px 0px 0px 0px;
//   -webkit-appearance: none;
// }

// #mobileMenuContainer {
//   display: none !important;
// }

/** Firefox specific styles removed **/
// ::-moz-focus-inner {
//   border: none;
// }

// select {
//   padding-top: 4px;
//   padding-bottom: 3px;
// }

// .highslide-html div {
//   overflow: hidden !important;
// }
