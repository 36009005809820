.a-btn--primary {
  background-color: #00386d !important;
  border-color: #00386d !important;
  border-radius: 2px;
  color: #ffffff !important;
  text-decoration: none !important;
  padding: ru(0.25) ru(1) !important;
  font-size: 18px !important;
  line-height: 27px;

  &:hover {
    background-color: #012446 !important;
  }
}
