
.card--index {
  display: flex;
  flex-direction: column;
  border: 1px solid palette('grey', 600);
  border-radius: 4px;
  background-color: palette('white');
  height: 100%;

  &.primary {
    border-color: palette('blue', 250);
  }

  &.secondary {
    border-color: palette('blue', 400);
  }

  &.tertiary {
    border-color: palette('teal');
  }
}

.card--index__title {
  border-bottom: 5px solid palette('grey', 600);
  padding: ru(0.5);
  color: palette('grey', 300) !important;

  &.primary {
    border-color: palette('blue', 250);
  }

  &.secondary {
    border-color: palette('blue', 400);
  }

  &.tertiary {
    border-color: palette('teal');
  }
}

.card--index__content {
  padding: ru(0.5);
}

.card--index__actions {
  margin-top: auto;
  border-top: 2px solid palette('grey', 600);

  &.primary {
    border-color: palette('blue', 250);
  }

  &.secondary {
    border-color: palette('blue', 400);
  }

  &.tertiary {
    border-color: palette('teal');
  }
}

// .card--index__action {

// }

.card--index__btn {
  display: block;
  padding: ru(0.5);
  text-align: center;
  text-decoration: none;
  color: palette('grey', 300);

  &:hover {
    background-color: palette('grey', 400);
    color: palette('white');

    &.primary {
      background-color: palette('blue', 250);
    }

    &.secondary {
      background-color: palette('blue', 400);
    }

    &.tertiary {
      color: palette('grey', 300);
      background-color: palette('teal');
    }
  }
}
