.m-tweet-block {
  border: 1px solid palette('grey', 600);
  border-radius: 25px;
  background-color: palette('white');
  padding: ru(1);
}

.m-tweet-block__quote {
  margin: 0 auto ru(1);
  max-width: ru(3);
}

.m-tweet-block__quote img {
  display: block;
  width: 100%;
  height: auto;
}

.m-tweet-block__media-wrapper {
  margin: 0 auto ru(0.5);
  max-width: ru(16);
}

.m-tweet-block__media-img {
  display: block;
  border: 1px solid palette('blue', 900);
  width: 100%;
  height: auto;
}

.m-tweet-block__tweet {
  margin-bottom: ru(2);
  text-align: center;
  line-height: 24px !important;
  font-size: 20px !important;
}

.m-tweet-block__meta {
  display: flex;
}

.m-tweet-block__profile-img-wrapper {
  width: ru(2);

  img {
    border-radius: 50%;
    width: 100%;
    height: auto;
  }
}

.m-tweet-block__authoring {
  flex-grow: 1;
  padding-left: 15px;
}

.m-tweet-block__handle {

  a {
    line-height: 24px !important;
    color: palette('grey', 300) !important;
    font-size: 18px !important;
    font-weight: 700 !important;

    &:hover {
      color: palette('blue', 400) !important;
    }
  }
}

.m-tweet__logo {
  align-self: flex-end;
}
