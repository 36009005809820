.sectionSummary {
  
  img {
    height: auto !important;
  }
}

.a-img {
  display: block;
  width: 100%;
  height: auto;
}
