.m-logo {
  margin: 0 auto;
}

.m-logo__link {
  display: block;
}

.m-logo__img {
  display: block;
  width: 100%;
  height: auto;
}
