.card--info {
  display: flex;
  flex-direction: column;
  border: 1px solid palette('grey', 750);
  border-radius: 4px;
  height: 100%;
}

.card--info__main {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.card--info__img-wrapper {
  // display: flex;
  // align-items: center;
  // background-color: palette('black');
  // padding: ru(0.5);
  width: 50%;
}

.card--info__img {
  width: 100%;
  height: 100%;
  display: block;
  // margin: 0 auto;
  object-fit: cover;
  max-width: none;
  // overflow: hidden;
}

.card--info__title {
  line-height: 34px;
  font-size: 28px;
}

.card--info__content {
  padding: ru(0.5);
  width: 100%;

  .card--info.has-img & {
    width: 50%;
  }
}

// .card--info__body {

// }

.card--info__actions {
  border-top: 1px solid palette('grey', 750);
  width: 100%;

}

// .card--info__action {

// }

.card--info__btn {
  display: block;
  border: 1px solid transparent;
  border-radius: 0 0 4px 4px;
  background-color: palette('grey', 950);
  padding: ru(0.5);
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: palette('blue');
  text-decoration: none;

  .g-svg--inline__wrapper {
    display: inline-block;
    position: relative;
    margin-left: ru(0.125);
    width: ru(0.5);
    height: ru(0.5);
    vertical-align: middle;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    background-color: palette('blue', 250);
    width: 100%;
    color: palette('white');
  }
}
