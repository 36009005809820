
/** HACK CODE TO FIX CRAPPY THEME **/
// @todo rebuild the theme.
.front #contentContainerInner {
  width: 100% !important;
  max-width: none !important;
  padding: 0 !important;
}

.l-page__logo {
  margin: 0 auto;
  padding: ru(1) 0;
  width: ru(8);
  margin: 0 auto;

  @include breakpoint($bp-desktop) {
    width: ru(12);
  }
}

.l-page__header-inner {
  position: relative;
}

.l-page__mobile-trigger {
  position: absolute;
  top: ru(0.5);
  right: ru(0.5);
  width: ru(1.5);
  height: ru(1.5);

  @include breakpoint($bp-desktop) {
    display: none;
  }

  button {
    border: 0;
    padding: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.l-page__search--desktop,
.l-page__search--mobile {
  display: flex;
  justify-content: center;
}

.l-page__search--desktop {
  display: none;

  @include breakpoint($bp-desktop) {
    position: absolute;
    right: ru(0.5);
    top: ru(0.5);
    display: flex;
  }
}

.l-page__search--mobile {
  margin: ru(0.25);

  @include breakpoint($bp-desktop) {
    display: none;
  }
}

.l-page__mobile-navigation {

  &[aria-hidden='true'] {
    display: none;
  }

  @include breakpoint($bp-desktop) {
    display: none;
  }
}

.l-page__main-navigation {
  display: none;

  @include breakpoint($bp-desktop) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    border-bottom: 1px solid palette('white');
  }

  @include breakpoint($bp-full-nav-complete) {
    -ms-grid-columns: 1fr ru(12.5);
    grid-template-columns: 1fr ru(12.5);
    -ms-grid-rows: ru(0.5) auto;
    grid-template-rows: ru(0.5) auto;
  }
}

.l-page__menu-main-wrapper {

  @include breakpoint($bp-desktop) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 2; 
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    background-color: palette('blue', 250);
  }

  @include breakpoint($bp-full-nav-complete) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 2;
    grid-column: 1 / span 2;
  }
}

.l-page__menu-main {

  @include breakpoint($bp-desktop) {
    margin: 0 auto;
    padding-right: ru(0.5);
    padding-left: ru(0.5);
    max-width: 1150px;
  }

  @include breakpoint($bp-full-nav-complete) {
    padding-right: ru(12.5);
  }

  .m-menu-main.desktop {
    display: none;

    @include breakpoint($bp-desktop) {
      display: block;
    }
  }

  .m-menu-main.mobile {

    @include breakpoint($bp-desktop) {
      display: none;
    }
  }
}

.l-page__menu-user-wrapper {

  @include breakpoint($bp-desktop) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 1; 
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
  }

  @include breakpoint($bp-full-nav-complete) {
    -ms-grid-column: 2; 
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    -ms-grid-row: 1; 
    -ms-grid-row-span: 2;
    grid-row: 1 / span 2;
  }

  @include breakpoint($bp-full-nav-complete) {
    position: relative;
  }
}

.l-page__menu-user {

  @include breakpoint($bp-desktop) {
    display: flex;
    justify-content: flex-end;
    margin-right: ru(0.5);
    margin-bottom: ru(0.5);
  }
}

#pageContainerOuter {
  flex-grow: 1;
}

/**
 * WPR Listing Page
 ***/
 .view-amapceo-people-rep-listing .views-exposed-widgets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: ru(2) ru(-0.25) ru(1);

  > div {
    float: none;
    padding: 0 ru(0.25) ru(1) ru(0.25);
    width: 50%;

    @include breakpoint($bp-2col) {
      width: 25%;
    }

    &.is-hidden {
      display: none;
    }
  }

  input[type=text] {
    padding: 8px 6px;
    width: 100%;
    height: 43px;
  }

  #autocomplete li {
    white-space: normal;
  }

  select {
    width: 100%;
  }

  .views-submit-button {
    width: 100%;

    input {
      margin-top: 0;
      border-color: palette(blue, 350);
      background-color: palette(blue, 350);
      padding: 9px;
      min-width: 100px;
      line-height: 23px;
      color: palette(white);
      font-size: 16px;
      font-weight: bold;

      &:hover,
      &:active {
        border-color: palette(blue, 300);
        background-color: palette(blue, 300);
      }
    }
  }
}

// WPR filters
.view-amapceo-people-rep-listing.view-display-id-block_wpr .views-exposed-widgets {

  // Bargaining filter.
  .views-widget-filter-field_bargaining_unit_tid {

    @include breakpoint($bp-2col) {
      order: 1;
    }
  }

  // District filter.
  .views-widget-filter-field_people_district_tid {

    @include breakpoint($bp-2col) {
      order: 3;
    }
  }

  // Ministry filter.
  .views-widget-filter-field_ministry_tid {

    @include breakpoint($bp-2col) {
      order: 2;
    }
  }

  // Community filter.
  .views-widget-filter-field_people_district_tid_1 {

    @include breakpoint($bp-2col) {
      order: 4;
    }
  }

  .views-submit-button {

    @include breakpoint($bp-2col) {
      order: 5;
    }
  }
}

.view-amapceo-people-rep-listing .view-footer {
  margin: ru(0.5) 0;
  text-align: center;
}

/**
 * Help Page
 ***/
.view-help .views-exposed-widgets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: ru(2) ru(-0.25) ru(1);

  > div {
    float: none;
    padding: 0 ru(0.25) ru(1) ru(0.25);
    width: 50%;
  }

  select {
    width: 100%;
  }

  .views-submit-button {
    padding: 0 30px;
  }
}
