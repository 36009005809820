.m-card-info {
  display: flex;
  flex-direction: column;
  border: 1px solid palette('blue', 250);
  border-radius: 4px;
  background-color: palette(white);
  height: 100%;
}

.m-card-info__header {
  margin-bottom: ru(1);
  border-bottom: 5px solid palette('blue', 250);
  padding: ru(0.5);
}

.m-card-info__name {
  margin-bottom: 0;
}

// .m-card-info__ministry {

// }

.m-card-info__content {
  margin: ru(1) 0;
  padding: 0 ru(0.5);
}

// .m-card-info__city {

// }

// .m-card-info__address {

// }

.m-card-info__footer {
  margin-top: auto;
  // border-top: 2px solid palette('blue', 250);
  padding: 0 ru(0.5) ru(0.5);
}

.m-card-info__email {

  a {

    &:hover,
    &:active {
      color: palette(blue, 250);
    }
  }
}
