.o-footer__suffix {

}

.o-footer__copyright {
  text-align: center;
  text-transform: uppercase;
  line-height: 1.3em;
  color: #b6b9bf;
  font-size: 13px;
}
