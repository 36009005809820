.l-listing--feature-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);

  @include breakpoint($bp-2col) {
    -ms-grid-columns: 1fr ru(2) 1fr;
    grid-template-columns: 1fr ru(2) 1fr;
    -ms-grid-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  }

  .m-listing-item {
    margin-bottom: ru(1);
  }
}

%l-listing__title,
.l-listing__title {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 1; 
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  margin-bottom: ru(0.75);
  line-height: 36px !important;
  color: palette('grey', 300) !important;
  font-size: 28px !important;
  font-weight: 700 !important;

  @include breakpoint($bp-type-large) {
    line-height: 42px !important;
    font-size: 36px !important;
  }

  @include breakpoint($bp-2col) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 3;
    grid-column: 1 / span 3;
    -ms-grid-row: 1; 
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
  }

  .l-listing--light & {
    color: palette('white') !important;
  }
}

.l-listing__feature {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 2; 
  -ms-grid-row-span: 1;
  grid-row: 2 / span 1;
  margin-bottom: ru(1);

  @include breakpoint($bp-2col) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 2; 
    -ms-grid-row-span: 2;
    grid-row: 2 / span 2;
    margin-bottom: 0;
    padding-right: ru(2);
  }

  &.limit {
    margin-right: auto;
    margin-left: auto;
    max-width: ru(20);
  }

  // Full length card to overlap boundry.
  > .m-card {

    @include breakpoint($bp-2col) {
      height: 100%;
    }
  }
}

.l-listing__list {
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 3; 
  -ms-grid-row-span: 1;
  grid-row: 3 / span 1;

  @include breakpoint($bp-2col) {
    -ms-grid-column: 3; 
    -ms-grid-column-span: 1;
    grid-column: 3 / span 1;
    -ms-grid-row: 2; 
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
  }
}

%l-listing__actions,
.l-listing__actions {
  display: flex;
  -ms-grid-column: 1; 
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 4; 
  -ms-grid-row-span: 1;
  grid-row: 4 / span 1;
  justify-content: flex-end;
  align-items: flex-end;

  @include breakpoint($bp-2col) {
    -ms-grid-column: 1; 
    -ms-grid-column-span: 3;
    grid-column: 1 / span 3;
    -ms-grid-row: 3; 
    -ms-grid-row-span: 1;
    grid-row: 3 / span 1;
  }
}

.l-listing__actions--start {
  @extend %l-listing__actions;

  justify-content: flex-start;
  align-items: flex-start;
}

.l-listing,
.l-listing--light {

  > .m-listing-item {
    margin-bottom: ru(1);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.l-listing__empty--white {
  color: palette('white');
}

.l-listing--2col {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > .m-listing-item {
    margin-bottom: ru(1);
    padding: 0 ru(0.5);
    width: 100%;

    @include breakpoint($bp-2col) {
      width: 50%;

      &:nth-child(2n+1) {
        padding-left: 0;
      }

      &:nth-child(2n+2) {
        padding-right: 0;
      }
    }
  }
}

// Group Listing
.l-listing--group {
  // background-color: palette(grey, 950);
  margin-top: ru(1);
}

.l-listing--group__title {
  line-height: 32px;
  font-size: 24px;
}

.l-listing--group__content {
  background-color: palette(blue, 900);
  padding: ru(0.5) ru(0.25);
}

.l-listing--group__content--accordions {
  @extend %u-remove-child--margin-bottom;

  background-color: transparent;
}

.l-listing--group__empty {
  background-color: palette(blue, 900);
  padding: ru(2) ru(0.5);
  text-align: center;
}
