.m-accordion {

  &.is-important {
    border-color: palette('blue', 250);
  }
}

.m-accordion__label {
  
  &.is-important {
    position: relative;
    padding-right: 35px;
  }

  i {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid;
    padding-top: 2.5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
  }
}
