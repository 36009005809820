/// Accessible way to hide elements on the page (and show them again)
///
/// Use as a mixin (discouraged), class, or @extend (preferred)
/// @group utility
/// @example scss - Sass Usage
///  .my-module {
///    @extend %u-visually--hidden;
///   }
/// @example markup - Html usage
///  <div class="my-module visually-hidden">Hide Text</div>
///
@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;

  &.focusable {
    background-color: palette(grey, 300);
    padding: ru(0.5);
    // color: palette(blue, 200);

    &:active,
    &:focus {
      position: static;
      overflow: visible;
      clip: auto;
      margin: 0;
      width: auto;
      height: auto;
      text-decoration: underline;
    }
  }
}

/// Accessible way to hide elements on the page (and show them again)
///
/// Use as a mixin (discouraged), class, or @extend (preferred)
/// @group utility
/// @example scss - Sass Usage
///  .my-module {
///    @extend %visually-shown;
///   }
/// @example markup - Html usage
///  <div class="my-module visually-shown">Hide Text</div>
///
@mixin visually-shown {
  position: static;
  overflow: visible;
  clip: auto;
  clip: rect(auto auto auto auto); // sass-lint:disable-line no-duplicate-properties
  margin: 0;
  width: auto;
  height: auto;
}

%u-visually--hidden,
.u-visually--hidden {
  @include visually-hidden;
}

%u-visually--shown,
.u-visually--shown {
  @include visually-shown;
}
