.m-poll {
  border: 1px solid palette('grey', 750);
  border-radius: 4px;
  padding: ru(0.5);
}

.m-poll form label {
  cursor: pointer;
}

.m-poll .poll-message {
  margin: ru(0.5) 0;
}

.m-poll .draggable a.tabledrag-handle {
  margin-left: 0;
}

.m-poll a.tabledrag-handle .handle {
  background-position: 6px 6px;
  height: 25px;
  margin: auto;
  padding: 5px;
  width: 25px;
}

.m-poll a.tabledrag-handle-hover .handle {
  background-position: 6px -14px;
}

.m-poll .advpoll-ranking-wrapper ul.selectable-list {
  list-style-position: inside;
  width: 100%;
}

.m-poll .advpoll-ranking-table-wrapper {
  margin: 0 auto;
}

.m-poll .advpoll-ranking-draggable {
  width: 100%;
  max-width: 400px;
  margin: 10px 0;
}

.m-poll .poll .bar .foreground {
  background-color: palette(blue, 350);
}

.m-poll .poll-bar {
  margin-bottom: 5px;
}

.m-poll .poll .voted .bar {
  border: 0;
}
