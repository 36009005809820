.o-carousel {
  position: relative;
}

/* Play / Pause button */
.carousel__btn--animate {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  overflow: hidden;
  border: 0;
  border-radius: 0;
  background-color: rgba(13, 35, 69, 0.67);
  background-image: url('../img/pause.png');
  background-position: 50% 50%;
  background-size: 32px 32px;
  width: 36px;
  height: 36px;

  &:hover,
  &:focus {
    background-color: palette(white);
    background-image: url('../img/pause-black.png') !important;
  }

  &[data-carousel-state='play'] {
    background-image: url('../img/play.png');

    &:hover,
    &:focus {
      background-color: palette(white);
      background-image: url('../img/play-black.png');
    }
  }
}

.o-carousel .slick-dots {
  margin-bottom: 0;
  background: rgba(13, 35, 69, 0.65);
  padding: 6px;
  z-index: 9;


  @include breakpoint($bp-2col) {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.o-carousel .slick-dots li {
  display: inline-block;
  margin: 0;
  line-height: 20px;
}

%o-carousel__btn--control {
  position: absolute;
  top: 50%;
  z-index: 9;
  margin-top: -13px;
  border: 0;
  background-color: rgba(13, 35, 69, 0.65);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 36px;
  padding: 2px;
  width: 40px;
  height: 40px;
  text-indent: -9999px;

  &:hover {
    background-color: palette(white);
  }

  .mobileVersion & {
    display: none;
  }
}

.o-carousel .slick-arrow {
  border-radius: 0;
}

.o-carousel .slick-prev {
  @extend %o-carousel__btn--control;
  left: 10px;
  background-image: url('../img/arrow-circle-left-solid-white.png');

  &:hover {
    background-color: palette(white);
    background-image: url('../img/arrow-circle-left-solid-black.png');
  }
}

.o-carousel .slick-next {
  @extend %o-carousel__btn--control;
  right: 10px;
  background-image: url('../img/arrow-circle-right-solid-white.png');

  &:hover {
    background-color: palette(white);
    background-image: url('../img/arrow-circle-right-solid-black.png');
  }
}

// .o-carousel__slide-img-wrapper {

// }

.o-carousel__slide-img-link {
  display: block;
}

.o-carousel__slide-img {
  display: block;
  margin: 0px auto;
  width: 100%;
  
  body.desktopVersion.widthConstrained & {
    max-width: 1600px;
  }

  body.desktopVersion.minWidthConstrained & {
    min-width: 768px;
  }
}

// Hide content.
.o-carousel__slide-content {
  display: none;
}
