body.page-dashboard {

  h1.title {
    @extend %u-visually--hidden;
  }

  #contentContainerOuter {
    margin-bottom: 0;
  }

  #contentContainerInner {
    padding: 0;
    min-width: 0 !important;
    max-width: none;
  }

  .l-page__tabs,
  .l-page__messages {
    margin: ru(1) auto;
    padding: 0 ru(0.5);
    max-width: $bp-max;
  }

  .l-page__messages .messages {
    width: 100%;
  }
}

.l-dashboard {
  margin: ru(1) 0 0;
}

.l-dashboard__prefix {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto ru(3);
  padding: 0 ru(0.5);
  max-width: $bp-max;

  @include breakpoint($bp-desktop) {
    flex-direction: row;
  }
}

.l-dashboard__aside {
  order: 1;
  margin-bottom: ru(1);
  width: 100%;

  @include breakpoint($bp-desktop) {
    order: 2;
    padding-left: ru(0.5);
    width: 35%;
  }

  .l-dashboard__item,
  .block-advpoll {
    margin-bottom: ru(1);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.l-dashboard__main {
  order: 2;
  width: 100%;

  @include breakpoint($bp-desktop) {
    order: 1;
    padding-right: ru(0.5);
    width: 65%;
  }

  .l-dashboard__item {
    margin-bottom: ru(2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

%l-dashboard__section,
.l-dashboard__section {
  padding-bottom: ru(3);
}

.l-dashboard__section--2col {
  @extend %l-dashboard__section;

  display: flex;
  flex-wrap: wrap;
  margin: 0 ru(-0.5);

  > .l-grid__item {
    width: 100%;

    @include breakpoint($bp-desktop) {
      width: 50%;
    }
  }
}

.l-dashboard__section-title {
  margin-bottom: ru(1);
  padding-top: ru(3);
  line-height: 36px !important;
  color: palette('grey', 300) !important;
  font-size: 28px !important;
  font-weight: 700 !important;

  @include breakpoint($bp-type-large) {
    line-height: 42px !important;
    font-size: 36px !important;
  }
}

.l-dashboard__section--4col {
  @extend %l-dashboard__section;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 ru(-0.5);
  padding-bottom: ru(2.5);

  > .l-grid__item {
    margin-bottom: ru(1);
    width: 100%;

    @include breakpoint(300px) {
      width: 50%;
    }

    @include breakpoint($bp-type-large) {
      width: 1/3 * 100%;
    }

    @include breakpoint($bp-desktop) {
      width: 25%;
    }
  }
}

.l-dashboard__section--3col {
  @extend %l-dashboard__section;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 ru(-0.5);
  padding-top: ru(3);
  padding-bottom: ru(2.5);

  > .l-grid__item {
    width: 100%;

    @include breakpoint($bp-type-large) {
      width: 50%;
    }

    @include breakpoint($bp-desktop) {
      justify-content: flex-start;
      width: 1/3 * 100%;
    }
  }
}

.l-dashboard__section-wrapper {
  margin: 0 auto;
  padding-right: ru(0.5);
  padding-left: ru(0.5);
  max-width: $bp-max;
}
