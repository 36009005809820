.m-menu-user {

}

.m-menu-user__menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include breakpoint($bp-desktop) {
    display: flex;
  }
}

.m-menu-user__menu-item {
  border-bottom: 1px solid palette('white');

  @include breakpoint($bp-desktop) {
    margin: 0 ru(0.5) 0 0;
    border-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.m-menu-user__link.primary {
  display: block;
  position: relative;
  border-color: #0091c0 !important;
  background-color: #0091c0;
  padding: ru(0.5) ru(2) ru(0.5) ru(0.5);
  width: 100%;
  text-transform: capitalize;
  text-decoration: none;
  line-height: 24px;
  color: #ffffff !important;
  font-size: 18px;

  @include breakpoint($bp-desktop) {
    border-radius: 2px;
    width: auto;
  }

  @include breakpoint($bp-full-nav-lrg) {
    font-size: 20px;
  }

  &:hover {
    background-color: palette('blue', 300);
  }

  &:after {
    position: absolute;
    right: ru(0.5);
    top: 50%;
    margin-top: -12px;
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px;
    content: '';
  }
}

.m-menu-user__link.primary.login:after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTQgNTE0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTQgNTE0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik00MTYsNDQ4aC04NGMtNi42LDAtMTItNS40LTEyLTEydi00MGMwLTYuNiw1LjQtMTIsMTItMTJoODRjMTcuNywwLDMyLTE0LjMsMzItMzJWMTYwYzAtMTcuNy0xNC4zLTMyLTMyLTMyCgloLTg0Yy02LjYsMC0xMi01LjQtMTItMTJWNzZjMC02LjYsNS40LTEyLDEyLTEyaDg0YzUzLDAsOTYsNDMsOTYsOTZ2MTkyQzUxMiw0MDUsNDY5LDQ0OCw0MTYsNDQ4eiBNMzY5LDI0N0wyMDEsNzkKCWMtMTUtMTUtNDEtNC41LTQxLDE3djk2SDI0Yy0xMy4zLDAtMjQsMTAuNy0yNCwyNHY5NmMwLDEzLjMsMTAuNywyNCwyNCwyNGgxMzZ2OTZjMCwyMS41LDI2LDMyLDQxLDE3bDE2OC0xNjgKCUMzNzguMywyNzEuNiwzNzguMywyNTYuNCwzNjksMjQ3eiIvPgo8L3N2Zz4K');
}

.m-menu-user__link.primary.dashboard:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAi1JREFUeNrsWotxwjAMDUzACO4EZYOaCcoIdIOOkE7ACLABdALCBNAJkk5gOoEr9UTLhfgT4o+467vTHQnYp2dJlixTFIGhtRYgJUit/1DTO1FwBSgnQVbajQ3+lovSE5BFa7V9gWNecY4cigtabaWHQ9Fc0xSK42rvdDzg3IsUQRkbanDQ9whKn6CtB46XKYLSBBnI/exBj0EUyU1k4A1AXQU8rXwsH5eRtuAfS4xpvjlI0iw5Go1OIGuQB3icgax7DBek8y+Bp5wJEUhUIC/wEcm8gTQewx4vCbAAkGhASrIKEqpcY84EPrnVVuReMwuJL3YWuAX/BBJA+BCo7pBAxd4CPiX2+LwP35v7nHW+tEDDkIDJAk1XEB8ZEjBVCMcuAntm/o/Fmukc8NFFgFsc2A4x1RUBCAo0y4kRgWfD+9PlptPeRreM3Md0uN/aMvE7k9WfW77bu9irFCcyhw6mU5ryqYXWmd1nYUlgW58JRE4LOM7IwmkBPBXlCmZsm9iKN9LNu6mV1ALUpVB95xlbCqXUiW0FMrGsftXXnCKVBajjZsP0Vp9cxiZAHUEbloMyYoCOnXQor3w6cENIyIitxTp0EjSRKEMS8Fh5RBl6j96FIEAWdSm/iVIhghyGEPC05CHaxZ9HsukkQC6zy6p8T/9tX9xpFsq3ktwhYLmRTvmWO4W4ct1kuewOtMWWBQdQoNY9XWZacANZQ7kusFn3wik2TH+3Ce7r3wIMAFxPqE5T0V0+AAAAAElFTkSuQmCC')
}


.m-menu-user__link.secondary {
  font-size: 18px;
  line-height: 24px;
  display: block;
  background-color: palette('blue', 900);
  border-color: palette('blue', 900);
  color: palette('grey', 300);
  padding: ru(0.5);
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    background-color: palette('grey', 300);
    border-color: palette('grey', 300);
    color: palette('white');
  }

  @include breakpoint($bp-desktop) {
    border-radius: 2px;
  }

  @include breakpoint($bp-full-nav-lrg) {
    font-size: 20px;
  }
}
