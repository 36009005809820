%m-card,
.m-card {
  border: 1px solid palette('grey', 600);
  border-radius: 2px;
  background-color: palette('white');
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}

.m-card--plain {
  @extend %m-card;
  padding: ru(0.75);

  > p,
  li {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.m-card__content {
  padding: ru(0.75);

  &.has-img {
    position: relative;
  }
}

// .m-card__img-wrapper {

// }

.m-card__img-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.m-card__tag {
  display: inline-block;
  padding: ru(0.25) ru(0.5);
  text-transform: uppercase;
  line-height: 18px !important;
  letter-spacing: normal;
  color: palette('white');
  font-size: 14px !important;
  font-weight: 400 !important;

  &.has-img {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
  }

  &.is-grey {
    background-color: palette('grey', 300);
  }

  &.is-blue {
    background-color: palette('blue', 400);
  }
}

.m-card__date {
  margin-top: ru(1);

  span {
    line-height: 18px !important;
    letter-spacing: normal !important;
    color: palette('grey', 300);
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.m-card__title {
  margin-bottom: 0 !important;

  a {
    // text-decoration: none !important;
    line-height: 26px !important;
    letter-spacing: normal !important;
    color: palette('grey', 300) !important;
    font-size: 22px !important;
    font-weight: 700 !important;

    @include breakpoint($bp-type-large) {
      line-height: 32px !important;
      font-size: 28px !important;
    }

    &:hover {
      text-decoration: underline !important;
      color: palette('blue', 250) !important;
    }
  }
}
