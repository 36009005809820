.m-share {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-share__title {
  margin-right: ru(1);
  line-height: 28px !important;
  color: palette('white') !important;
  font-size: 24px !important;
  font-weight: 700 !important;

  @include breakpoint($bp-type-large) {
    line-height: 34px !important;
    font-size: 28px !important;
  }
}

.m-share__menu {
  display: block;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 0 !important;
  list-style-type: none !important;
}

.m-share__menu-item {
  display: inline-block;
  margin: ru(0.25) ru(0.5) ru(0.25) 0 !important;
  padding: 0 !important;

  .text {
    @extend %u-visually--hidden;
  }
}

.m-share__link {
  display: block;
  position: relative;

  &:hover {

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.25;
      z-index: 1;
      background-color: palette('blue', 250) !important;
      width: 100%;
      height: 100%;
      content: '';
    }
  }
}

.m-share__menu-item img {
  display: block;
  width: ru(1.5);

  @include breakpoint($bp-type-large) {
    width: ru(2);
  }
}
