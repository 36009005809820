%l-grid,
.l-grid {
  display: flex;
  flex-wrap: wrap;
}

.l-grid__item {
  margin-bottom: ru(0.5);
  padding-right: ru(0.5);
  padding-left: ru(0.5);
  width: 100%;
}

/* .l-grid--2col-asymemtrical-3\:2 {

} */


/* .l-grid--4col .l-grid__item {

} */

@media (min-width: 1024px) {

  .l-grid--4col .l-grid__item {
    width: 50%;
  }
}

@media (min-width: 1024px) {

  .l-grid--4col .l-grid__item {
    width: 25%;
  }
}

/* .l-grid--2col-asymemtrical-3\:2 .l-grid__item {

} */

/* .l-grid--2col-asymemtrical-3\:2 .l-grid__item:nth-child(2n+1) {

} */

@media (min-width: 680px) {

  .l-grid--2col-asymemtrical-3\:2 .l-grid__item:nth-child(2n+1) {
    width: 60%;
  }
}

/* .l-grid--2col-asymemtrical-3\:2 .l-grid__item:nth-child(2n+2) {

} */

@media (min-width: 680px) {

  .l-grid--2col-asymemtrical-3\:2 .l-grid__item:nth-child(2n+2) {
    width: 40%;
  }
}

.l-grid__item--stretch {
  display: flex;
  flex-basis: auto;
}

// Legacy ^^

%l-flex {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-bottom: ru(0.5);
    padding-right: ru(0.25);
    padding-left: ru(0.25);
    width: 100%;
  }
}

.l-flex--3col {
  @extend %l-flex;

  > * {

    @include breakpoint($bp-2col) {
      width: 50%;
    }

    @include breakpoint($bp-full-nav-complete) {
      width: 33.33333%;
    }
  }
}
