// Fonts
//
// Fonts these are all the default @fontface declarations
//
// NOTE: For IE9+ only woff is needed.
// See https://webdevstudios.com/2016/05/26/css-font-face-2016-edition/

// To generate optimized Open Source fonts listed on Google Web Fonts use:
// https://google-webfonts-helper.herokuapp.com/fonts

// To generate other optimized Open Source fonts use:
// https://transfonter.org

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  font-style: normal;
  src: local('Roboto Condensed Light'),
  local('RobotoCondensed-Light'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300.woff') format('woff');
}

/* roboto-condensed-300italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  font-style: italic;
  src: local('Roboto Condensed Light Italic'),
  local('RobotoCondensed-LightItalic'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-300italic.woff') format('woff');
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-style: normal;
  src: local('Roboto Condensed'),
  local('RobotoCondensed-Regular'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.woff') format('woff');
}
/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-style: italic;
  src: local('Roboto Condensed Italic'),
  local('RobotoCondensed-Italic'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-italic.woff') format('woff');
}

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-style: normal;
  src: local('Roboto Condensed Bold'),
  local('RobotoCondensed-Bold'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700.woff') format('woff');
}

/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-style: italic;
  src: local('Roboto Condensed Bold Italic'),
  local('RobotoCondensed-BoldItalic'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.woff2') format('woff2'),
  url('../fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
