// Max column width
// $max-col-width: grid-calc(1);

// Half column width
// $half-column-width: $max-col-width / 2;


%u-grid {
  // @extend %l-grid;
  // display: grid;
  // grid-template-columns: repeat($total-cols, minmax(min-content, $max-col-width));
  // grid-template-rows: 1fr;
  // grid-column-gap: $gutter;
  border: 2px dashed blue;
  background-color: rgba(blue, 0.5);
  padding: ru(0.5) 0;
}

.u-grid-debug,
%u-grid-debug {
  // @extend %u-grid;

  // Full screen overlay / underlay
  background-size: 100% $base-line-height;
  width: 100%;
  height: 100%;
}

%u-grid-debug__col,
.u-grid-debug__col {
  outline: 2px dashed #a00000;
  background-color: rgba(160, 0, 0, 0.5);
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  color: #ffffff;
}

.u-grid-debug--overlay {
  @extend %u-grid-debug;
  position: absolute;
  z-index: 1;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.u-grid-debug--overlay__col {
  @extend %u-grid-debug__col;
}
