.m-info-box {
  border: 1px solid palette('grey', 750);
  border-radius: 4px;
}

.m-info-box__title {
  margin-bottom: 0;
  padding: ru(0.5);
  line-height: 27px;
  color: palette('grey', 300) !important;
  font-size: 18px;
}

.m-info-box__content {
  border-top: 1px solid palette('grey', 750);
  padding: ru(1) ru(0.5) ru(0.5) ru(0.5);
}

.m-info-box__subtitle {
  line-height: 22px;
  color: palette('grey', 300) !important;
  font-size: 18px;
}

.m-info-box__details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.m-info-box__details-label {
  flex-grow: 0;
  flex-shrink: 0;
  margin: ru(0.25) 0;
  padding-right: ru(0.5);
  width: 40%;
  line-height: 24px !important;
  font-size: 16px !important;
}

.m-info-box__details-value {
  flex-grow: 0;
  flex-shrink: 0;
  margin: ru(0.25) 0;
  padding-left: ru(0.5);
  width: 60%;
  line-height: 24px !important;
  font-size: 16px !important;
}

%m-info-box__actions,
.m-info-box__actions {
  border-top: 1px solid palette('grey', 750);
}

.m-info-box__actions--split {
  @extend %m-info-box__actions;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .m-info-box__action {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;

    &:nth-child(2n+1) {
      align-self: flex-end;

      .m-info-box__link {
        padding-right: ru(0.25);
      }
    }

    &:nth-child(2n+2) {
      align-self: flex-start;

      .m-info-box__link {
        padding-left: ru(0.25);
      }
    }
  }
}

// .m-info-box__action {

// }

.m-info-box__link {
  display: block;
  padding: ru(0.5);
  text-decoration: none;
  color: palette('grey', 300);
  font-size: 16px;
  line-height: 24px;

  .g-svg--inline__wrapper {
    display: inline-block;
    position: relative;
    margin-left: ru(0.25);
    width: ru(0.75);
    height: ru(0.75);
    vertical-align: middle;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover,
  &:active {
    color: palette('blue', 300);
  }
}

.m-info-box__group {
  margin-bottom: ru(2);

  &:last-child {
    margin-bottom: 0;
  }
}

.m-info-box__table {
  display: table;
  width: 100%;
}

.m-info-box__table-header {
  display: table-header-group;
}

.m-info-box__row {
  display: table-row;
}

a.m-info-box__row {
  text-decoration: none;

  &:nth-child(even) {
    background-color: palette('grey', 950);
  }

  > div {

    &:nth-child(1),
    &:nth-child(3) {
      color: palette('blue', 250);
    }

    &:nth-child(2) {
      color: palette('grey', 300);
    }
  }

  &:hover {
    background-color: palette('blue', 300);
    color: palette('white');

    > div {
      color: palette('white');
    }
  }
}

.m-info-box__th,
.m-info-box__cell {
  display: table-cell;
  padding: ru(0.25);
}

.m-info-box__th {
  border-bottom: 1px solid palette('grey', 750);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.m-info-box__table-body {
  display: table-row-group;
}

.m-info-box__cell {
  vertical-align: middle;

  &:nth-child(2) {
    text-transform: uppercase;
  }

  &:last-child {
    width: 99%;
    text-align: right;
  }

  .g-svg__wrapper {
    position: relative;
    display: block;
    width: ru(0.5);
    height: ru(0.5);
    float: right;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.m-info-box__btn {
  display: block;
  border: 1px solid transparent;
  background-color: palette('grey', 950);
  padding: ru(0.5);
  text-align: center;
  text-decoration: none;
  color: palette('blue');
  font-size: 18px;
  line-height: 24px;

  &:hover {
    border-radius: 0 0 4px 4px;
    background-color: palette('blue');
    color: palette('white');
  }

  .g-svg--inline__wrapper {
    display: inline-block;
    position: relative;
    margin-left: ru(0.25);
    width: ru(0.75);
    height: ru(0.75);
    vertical-align: middle;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
