.card--action {
  display: flex;
  flex-direction: column;
  border: 1px solid palette('grey', 750);
  border-radius: 4px;
  background-color: palette('white');
  height: 100%;
}

.card--action__img-wrapper {
  border-bottom: 1px solid palette('grey', 750);
  flex-shrink: 0;
  flex-grow: 0;
}

.card--action__img {
  display: block;
  width: 100%;
  height: auto;
}

.card--action__content {
  flex-grow: 1;
  flex-shrink: 1;
  padding: ru(0.5);
}

.card--action__title {
  line-height: 34px;
  color: palette('grey', 300) !important;
  font-size: 28px;
}

// .card--action__body {

// }

.card--action__actions {
  border-top: 1px solid palette('grey', 750);
}

// .card--action__action {

// }

%card--action__btn,
.card--action__btn {
  display: block;
  border: 1px solid transparent;
  border-radius: 0 0 4px 4px;
  padding: ru(0.5);
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  color: palette('grey', 300);

  .g-svg--inline__wrapper {
    display: inline-block;
    position: relative;
    margin-left: ru(0.125);
    width: ru(0.5);
    height: ru(0.5);
    vertical-align: middle;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    background-color: palette('blue', 250);
    width: 100%;
    color: palette('white');
  }
}

.card--action__btn--external {
  @extend %card--action__btn;

  .g-svg--inline__wrapper {
    margin-left: ru(0.125);
  }
}
