.m-listing-item {
  display: flex;
  align-items: flext-start;
}

.m-listing-item__img-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  width: 124px;
}

.m-listing-item__img-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.m-listing-item__content {
  flex-grow: 1;
  align-self: center;
  padding: ru(0.5);
}

.m-listing-item__tag {
  display: inline-block;
  margin-right: ru(0.5);
  padding: ru(0.125) ru(0.25);
  line-height: 18px !important;
  color: palette('white');
  font-size: 14px !important;
  font-weight: 400 !important;

  &.is-grey {
    background-color: palette('grey', 300);
  }

  &.is-blue {
    background-color: palette('blue', 400);
  }
}

%m-listing-item__date,
.m-listing-item__date {
  display: inline-block;
  line-height: 18px !important;

  span {
    line-height: 18px !important;
    color: palette('grey', 300);
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .l-listing--light & {

    span {
      color: palette('white') !important;
    }
  }
}

%m-listing-item__title,
.m-listing-item__title {
  margin-top: ru(0.25);
  margin-bottom: 0 !important;

  a {
    // text-decoration: none !important;
    line-height: 24px !important;
    letter-spacing: normal !important;
    color: palette('grey', 300) !important;
    font-size: 18px !important;
    font-weight: 700 !important;

    &:hover {
      text-decoration: underline !important;
      color: palette('blue', 250) !important;
    }
  }

  .l-listing--light & {

    a {
      color: palette('white') !important;
  
      &:hover {
        text-decoration: underline !important;
        color: #000000 !important;
      }
    }
  }
}


/**
 * Listing Item Consensed
 */
.m-listing-item--condensed__content {
  padding: 0;
}
