
/** Main CSS file overides **/
// body .inPageContent {
// 	color: #666;
// }

.inPageContent .text-center {
	text-align: center;
}

.inPageContent div {
	font-weight: 400;
	line-height: 1.6em;
	font-size: 16px;
}

.inPageContent strong, .inPageContent b {
	font-style: normal;
	font-weight: 700;
}

.inPageContent h1, .inPageContent h2, .inPageContent h3, .inPageContent h4, .inPageContent h5, .inPageContent h6 {
	color: #00386d;
	font-weight: 400;
}

.inPageContent h1 {
	font-size: 220%;
	font-weight: 400;
	border-bottom: 1px solid #f5f5f5;
	display: block;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.inPageContent #leftColumn li + li, .inPageContent #rightColumn li + li, .inPageContent #content li + li {
	margin-top: 0px;
}

.first-paragraph {
	display:block;
	background-color: #f1f6f7;
	color: #00386d;
	padding: 16px;
}

.inPageContent .clearfix {clear:both;}
.inPageContent .field .field-label {
	font-weight: normal;
}

.inPageContent small,
.inPageContent .text--small {
	font-weight: normal;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input {
	line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

fieldset {
	border: 0px solid #c0c0c0!important;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
}

optgroup {
	font-weight: bold;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img {
	display: block;
	max-width: 100%;
	height: auto;
}

.img-rounded {
	border-radius: 0px;
}

.img-thumbnail {
	padding: 4px;
	line-height: 1.42857143;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 0px;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-circle {
	border-radius: 50%;
}

hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #eeeeee;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

[role="button"] {
	cursor: pointer;
}

table {
	background-color: transparent;
}

caption {
	padding-top: 8px;
	padding-bottom: 8px;
	color: #777777;
	text-align: left;
}

th {
	text-align: left;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 1px solid #dddddd;
}

.table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 2px solid #dddddd;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 0;
}

.table > tbody + tbody {
	border-top: 2px solid #dddddd;
}

.table .table {
	background-color: #ffffff;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
	padding: 5px;
}

.table-bordered {
	border: 1px solid #dddddd;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
	border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
	background-color: #f5f5f5;
}

table col[class*="col-"] {
	position: static;
	float: none;
	display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
	position: static;
	float: none;
	display: table-cell;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
	background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
	background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
	background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
	background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
	background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
	background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
	background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
	background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
	background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
	background-color: #ebcccc;
}

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%;
}

@media screen and (max-width: 767px) {

	.table-responsive {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #dddddd;
	}

	.table-responsive > .table {
		margin-bottom: 0;
	}

	.table-responsive > .table > thead > tr > th,
	.table-responsive > .table > tbody > tr > th,
	.table-responsive > .table > tfoot > tr > th,
	.table-responsive > .table > thead > tr > td,
	.table-responsive > .table > tbody > tr > td,
	.table-responsive > .table > tfoot > tr > td {
		white-space: nowrap;
	}

	.table-responsive > .table-bordered {
		border: 0;
	}

	.table-responsive > .table-bordered > thead > tr > th:first-child,
	.table-responsive > .table-bordered > tbody > tr > th:first-child,
	.table-responsive > .table-bordered > tfoot > tr > th:first-child,
	.table-responsive > .table-bordered > thead > tr > td:first-child,
	.table-responsive > .table-bordered > tbody > tr > td:first-child,
	.table-responsive > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}

	.table-responsive > .table-bordered > thead > tr > th:last-child,
	.table-responsive > .table-bordered > tbody > tr > th:last-child,
	.table-responsive > .table-bordered > tfoot > tr > th:last-child,
	.table-responsive > .table-bordered > thead > tr > td:last-child,
	.table-responsive > .table-bordered > tbody > tr > td:last-child,
	.table-responsive > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}

	.table-responsive > .table-bordered > tbody > tr:last-child > th,
	.table-responsive > .table-bordered > tfoot > tr:last-child > th,
	.table-responsive > .table-bordered > tbody > tr:last-child > td,
	.table-responsive > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}

}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 0px;
	font-size: 21px;
	line-height: inherit;
	color: #333333;
	border: 0;
	border-bottom: 1px solid #e5e5e5;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

input[type="search"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
	margin: -3px 6px 0px 0px;
	line-height: normal;
}

input[type="file"] {
	display: block;
}

input[type="range"] {
	display: block;
	width: 100%;
}

select[multiple],
select[size] {
	height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

output {
	display: block;
	padding-top: 7px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
}

.form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #cccccc;
	border-radius: 0px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
	color: #999999;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #999999;
}

.form-control::-webkit-input-placeholder {
	color: #999999;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #eeeeee;
	opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: not-allowed;
}

textarea.form-control {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

	input[type="date"].form-control,
	input[type="time"].form-control,
	input[type="datetime-local"].form-control,
	input[type="month"].form-control {
		line-height: 34px;
	}

	input[type="date"].input-sm,
	input[type="time"].input-sm,
	input[type="datetime-local"].input-sm,
	input[type="month"].input-sm,
	.input-group-sm input[type="date"],
	.input-group-sm input[type="time"],
	.input-group-sm input[type="datetime-local"],
	.input-group-sm input[type="month"] {
		line-height: 30px;
	}

	input[type="date"].input-lg,
	input[type="time"].input-lg,
	input[type="datetime-local"].input-lg,
	input[type="month"].input-lg,
	.input-group-lg input[type="date"],
	.input-group-lg input[type="time"],
	.input-group-lg input[type="datetime-local"],
	.input-group-lg input[type="month"] {
		line-height: 46px;
	}

}

.form-group {
	margin-bottom: 15px;
}

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}

.radio label,
.checkbox label {
	min-height: 20px;
	padding-left: 20px;
	margin-bottom: 0;
	font-weight: normal;
	cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: absolute;
	margin-left: -20px;
	margin-top: 4px;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-top: 0;
	margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
	cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
	cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
	cursor: not-allowed;
}

.form-control-static {
	padding-top: 7px;
	padding-bottom: 7px;
	margin-bottom: 0;
	min-height: 34px;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
	padding-left: 0;
	padding-right: 0;
}

.input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0px;
}

select.input-sm {
	height: 30px;
	line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
	height: auto;
}

.form-group-sm .form-control {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0px;
}

.form-group-sm select.form-control {
	height: 30px;
	line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
	height: auto;
}

.form-group-sm .form-control-static {
	height: 30px;
	min-height: 32px;
	padding: 6px 10px;
	font-size: 12px;
	line-height: 1.5;
}

.input-lg {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 0px;
}

select.input-lg {
	height: 46px;
	line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
	height: auto;
}

.form-group-lg .form-control {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 0px;
}

.form-group-lg select.form-control {
	height: 46px;
	line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
	height: auto;
}

.form-group-lg .form-control-static {
	height: 46px;
	min-height: 38px;
	padding: 11px 16px;
	font-size: 18px;
	line-height: 1.3333333;
}

.has-feedback {
	position: relative;
}

.has-feedback .form-control {
	padding-right: 42.5px;
}

.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
	width: 46px;
	height: 46px;
	line-height: 46px;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
	color: #3c763d;
}

.has-success .form-control {
	border-color: #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
	border-color: #2b542c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
	color: #3c763d;
	border-color: #3c763d;
	background-color: #dff0d8;
}

.has-success .form-control-feedback {
	color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
	color: #8a6d3b;
}

.has-warning .form-control {
	border-color: #8a6d3b;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
	border-color: #66512c;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
	color: #8a6d3b;
	border-color: #8a6d3b;
	background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
	color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
	color: #a94442;
}

.has-error .form-control {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
	color: #a94442;
	border-color: #a94442;
	background-color: #f2dede;
}

.has-error .form-control-feedback {
	color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
	top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
	top: 0;
}

.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: #737373;
}

@media (min-width: 768px) {

	.form-inline .form-group {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	
	.form-inline .form-control-static {
		display: inline-block;
	}

	.form-inline .input-group {
		display: inline-table;
		vertical-align: middle;
	}

	.form-inline .input-group .input-group-addon,
	.form-inline .input-group .input-group-btn,
	.form-inline .input-group .form-control {
		width: auto;
	}

	.form-inline .input-group > .form-control {
		width: 100%;
	}

	.form-inline .control-label {
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .radio,
	.form-inline .checkbox {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.form-inline .radio label,
	.form-inline .checkbox label {
		padding-left: 0;
	}

	.form-inline .radio input[type="radio"],
	.form-inline .checkbox input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	}

	.form-inline .has-feedback .form-control-feedback {
		top: 0;
	}

}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
	min-height: 27px;
}

.form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px;
}

@media (min-width: 768px) {

	.form-horizontal .control-label {
		text-align: right;
		margin-bottom: 0;
		padding-top: 7px;
	}

}

.form-horizontal .has-feedback .form-control-feedback {
	right: 15px;
}

@media (min-width: 768px) {

	.form-horizontal .form-group-lg .control-label {
		padding-top: 14.333333px;
		font-size: 18px;
	}

}

@media (min-width: 768px) {

	.form-horizontal .form-group-sm .control-label {
		padding-top: 6px;
		font-size: 12px;
	}

}

.btn {
	display: inline-block;
	font-family: 'Roboto Condensed', serif;
	margin-bottom: 0;
	text-align: center;
	vertical-align: middle;
	-ms-touch-action: manipulation;
		touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 0px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
	color: #333333;
	text-decoration: none;
}

.btn:active,
.btn.active {
	outline: 0;
	background-image: none;
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
	pointer-events: none;
}

.btn-default {
	color: #333333;
	background-color: #ffffff;
	border-color: #cccccc;
}

.btn-default:focus,
.btn-default.focus {
	color: #333333;
	background-color: #e6e6e6;
	border-color: #8c8c8c;
}

.btn-default:hover {
	color: #333333;
	background-color: #e6e6e6;
	border-color: #adadad;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	color: #333333;
	background-color: #e6e6e6;
	border-color: #adadad;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
	color: #333333;
	background-color: #d4d4d4;
	border-color: #8c8c8c;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
	background-color: #ffffff;
	border-color: #cccccc;
}

.btn-default .badge {
	color: #ffffff;
	background-color: #333333;
}

.btn-primary {
	color: #ffffff;
	background-color: #00386d;
	border-color: #2e6da4;
}

.btn-primary:focus,
.btn-primary.focus {
	color: #ffffff;
	background-color: #286090;
	border-color: #122b40;
}

.btn-primary:hover {
	color: #ffffff;
	background-color: #286090;
	border-color: #204d74;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	color: #ffffff;
	background-color: #286090;
	border-color: #204d74;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
	color: #ffffff;
	background-color: #204d74;
	border-color: #122b40;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
	background-color: #00386d;
	border-color: #2e6da4;
}

.btn-primary .badge {
	color: #00386d;
	background-color: #ffffff;
}

.btn-success {
	color: #ffffff;
	background-color: #4FC2C4;
	border-color: #4cae4c;
}

.btn-success:focus,
.btn-success.focus {
	color: #ffffff;
	background-color: #449d44;
	border-color: #255625;
}

.btn-success:hover {
	color: #ffffff;
	background-color: #449d44;
	border-color: #398439;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	color: #ffffff;
	background-color: #449d44;
	border-color: #398439;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
	color: #ffffff;
	background-color: #398439;
	border-color: #255625;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
	background-color: #4FC2C4;
	border-color: #4cae4c;
}

.btn-success .badge {
	color: #4FC2C4;
	background-color: #ffffff;
}

.btn-info {
	color: #ffffff;
	background-color: #007dae;
	border-color: #46b8da;
}

.btn-info:focus,
.btn-info.focus {
	color: #ffffff;
	background-color: #31b0d5;
	border-color: #1b6d85;
}

.btn-info:hover {
	color: #ffffff;
	background-color: #31b0d5;
	border-color: #269abc;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	color: #ffffff;
	background-color: #31b0d5;
	border-color: #269abc;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
	color: #ffffff;
	background-color: #269abc;
	border-color: #1b6d85;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
	background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
	background-color: #007dae;
	border-color: #46b8da;
}

.btn-info .badge {
	color: #007dae;
	background-color: #ffffff;
}

.btn-warning {
	color: #ffffff;
	background-color: #FF7D34;
	border-color: #eea236;
}

.btn-warning:focus,
.btn-warning.focus {
	color: #ffffff;
	background-color: #ec971f;
	border-color: #985f0d;
}

.btn-warning:hover {
	color: #ffffff;
	background-color: #ec971f;
	border-color: #d58512;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	color: #ffffff;
	background-color: #ec971f;
	border-color: #d58512;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
	color: #ffffff;
	background-color: #d58512;
	border-color: #985f0d;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
	background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
	background-color: #FF7D34;
	border-color: #eea236;
}

.btn-warning .badge {
	color: #FF7D34;
	background-color: #ffffff;
}

.btn-danger {
	color: #ffffff;
	background-color: #7D50B2;
	border-color: #d43f3a;
}

.btn-danger:focus,
.btn-danger.focus {
	color: #ffffff;
	background-color: #c9302c;
	border-color: #761c19;
}

.btn-danger:hover {
	color: #ffffff;
	background-color: #c9302c;
	border-color: #ac2925;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	color: #ffffff;
	background-color: #c9302c;
	border-color: #ac2925;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
	color: #ffffff;
	background-color: #ac2925;
	border-color: #761c19;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
	background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
	background-color: #7D50B2;
	border-color: #d43f3a;
}

.btn-danger .badge {
	color: #7D50B2;
	background-color: #ffffff;
}

.btn-link {
	color: #00386d;
	font-weight: normal;
	border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
	color: #23527c;
	text-decoration: underline;
	background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
	color: #777777;
	text-decoration: none;
}

.btn-lg,
.btn-group-lg > .btn {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	float: left;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
	z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px;
}

.btn-toolbar {
	margin-left: -5px;
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
	float: left;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
	margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}

.btn-group > .btn:first-child {
	margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group > .btn-group {
	float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
	padding-left: 8px;
	padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle {
	padding-left: 12px;
	padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn .caret {
	margin-left: 0;
}

.btn-lg .caret {
	border-width: 5px 5px 0;
	border-bottom-width: 0;
}

.dropup .btn-lg .caret {
	border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
}

.btn-group-vertical > .btn-group > .btn {
	float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
	margin-top: -1px;
	margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
	border-bottom-left-radius: 4px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
	border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
	float: none;
	display: table-cell;
	width: 1%;
}

.btn-group-justified > .btn-group .btn {
	width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
	left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.input-group[class*="col-"] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}

.input-group .form-control {
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 0px;
}

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	line-height: 46px;
}

textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
	height: auto;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 0px;
}

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 30px;
	line-height: 30px;
}

textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
	height: auto;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.input-group-addon,
.input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.input-group-addon {
	padding: 6px 12px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #555555;
	text-align: center;
	background-color: #eeeeee;
	border: 1px solid #cccccc;
	border-radius: 0px;
}

.input-group-addon.input-sm {
	padding: 5px 10px;
	font-size: 12px;
	border-radius: 0px;
}

.input-group-addon.input-lg {
	padding: 10px 16px;
	font-size: 18px;
	border-radius: 0px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.input-group-addon:first-child {
	border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.input-group-addon:last-child {
	border-left: 0;
}

.input-group-btn {
	position: relative;
	font-size: 0;
	white-space: nowrap;
}

.input-group-btn > .btn {
	position: relative;
}

.input-group-btn > .btn + .btn {
	margin-left: -1px;
}

.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
	z-index: 2;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: -1px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	z-index: 2;
	margin-left: -1px;
}

.breadcrumb {
	padding: 8px 15px;
	margin-bottom: 20px;
	list-style: none;
	background-color: #f5f5f5;
	border-radius: 0px;
}

.breadcrumb > li {
	display: inline-block;
}

.breadcrumb > li + li:before {
	content: "/\00a0";
	padding: 0 5px;
	color: #cccccc;
}

.breadcrumb > .active {
	color: #777777;
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 0px;
}

.pagination > li {
	display: inline;
}

.pagination > li > a,
.pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	line-height: 1.42857143;
	text-decoration: none;
	color: #00386d;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	z-index: 3;
	color: #23527c;
	background-color: #eeeeee;
	border-color: #dddddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	z-index: 2;
	color: #ffffff;
	background-color: #00386d;
	border-color: #00386d;
	cursor: default;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	color: #777777;
	background-color: #ffffff;
	border-color: #dddddd;
	cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

.pager {
	padding-left: 0;
	margin: 20px 0;
	list-style: none;
	text-align: center;
}

.pager li {
	display: inline;
}

.pager li > a,
.pager li > span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 0px;
}

.pager li > a:hover,
.pager li > a:focus {
	text-decoration: none;
	background-color: #eeeeee;
}

.pager .next > a,
.pager .next > span {
	float: right;
}

.pager .previous > a,
.pager .previous > span {
	float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
	color: #777777;
	background-color: #ffffff;
	cursor: not-allowed;
}

.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0em;
}

a.label:hover,
a.label:focus {
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
}

.label:empty {
	display: none;
}

.btn .label {
	position: relative;
	top: -1px;
}

.label-default {
	background-color: #777777;
}

.label-default[href]:hover,
.label-default[href]:focus {
	background-color: #5e5e5e;
}

.label-primary {
	background-color: #00386d;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
	background-color: #286090;
}

.label-success {
	background-color: #4FC2C4;
}

.label-success[href]:hover,
.label-success[href]:focus {
	background-color: #449d44;
}

.label-info {
	background-color: #007dae;
}

.label-info[href]:hover,
.label-info[href]:focus {
	background-color: #31b0d5;
}

.label-warning {
	background-color: #FF7D34;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
	background-color: #ec971f;
}

.label-danger {
	background-color: #7D50B2;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
	background-color: #c9302c;
}

.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: bold;
	color: #ffffff;
	line-height: 1;
	vertical-align: middle;
	white-space: nowrap;
	text-align: center;
	background-color: #777777;
	border-radius: 0px;
}

.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.btn-xs .badge,
.btn-group-xs > .btn .badge {
	top: 0;
	padding: 1px 5px;
}

a.badge:hover,
a.badge:focus {
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
}

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
	color: #00386d;
	background-color: #ffffff;
}

.list-group-item > .badge {
	float: right;
}

.list-group-item > .badge + .badge {
	margin-right: 5px;
}

.nav-pills > li > a > .badge {
	margin-left: 3px;
}

.jumbotron {
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	color: inherit;
	background-color: #eeeeee;
}

.jumbotron h1,
.jumbotron .h1 {
	color: inherit;
}

.jumbotron p {
	margin-bottom: 15px;
	font-size: 21px;
	font-weight: 200;
}

.jumbotron > hr {
	border-top-color: #d5d5d5;
}

.container .jumbotron,
.container-fluid .jumbotron {
	border-radius: 0px;
}

.jumbotron .container {
	max-width: 100%;
}

@media screen and (min-width: 768px) {
	.jumbotron {
	padding-top: 48px;
	padding-bottom: 48px;
	}
	.container .jumbotron,
	.container-fluid .jumbotron {
	padding-left: 60px;
	padding-right: 60px;
	}
	.jumbotron h1,
	.jumbotron .h1 {
	font-size: 63px;
	}
}
.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 0px;
	-webkit-transition: border 0.2s ease-in-out;
	-o-transition: border 0.2s ease-in-out;
	transition: border 0.2s ease-in-out;
}
.thumbnail > img,
.thumbnail a > img {
	margin-left: auto;
	margin-right: auto;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
	border-color: #00386d;
}
.thumbnail .caption {
	padding: 9px;
	color: #333333;
}
.alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 0px;
}
.alert h4 {
	margin-top: 0;
	color: inherit;
}
.alert .alert-link {
	font-weight: bold;
}
.alert > p,
.alert > ul {
	margin-bottom: 0;
}
.alert > p + p {
	margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
	padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit;
}
.alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d!important;
}
.alert-success hr {
	border-top-color: #c9e2b3;
}
.alert-success .alert-link {
	color: #2b542c;
}
.alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}
.alert-info hr {
	border-top-color: #a6e1ec;
}
.alert-info .alert-link {
	color: #245269;
}
.alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b!important;
}
.alert-warning hr {
	border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
	color: #66512c;
}
.alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442!important;
}
.alert-danger hr {
	border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
	color: #843534;
}
@-webkit-keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}
@-o-keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}




.progress {
	overflow: hidden;
	height: 20px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border-radius: 0px;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	color: #ffffff;
	text-align: center;
	background-color: #00386d;
	-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: width 0.6s ease;
	-o-transition: width 0.6s ease;
	transition: width 0.6s ease;
}
.progress-striped .progress-bar,
.progress-bar-striped {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	-webkit-background-size: 40px 40px;
			background-size: 40px 40px;
}
.progress.active .progress-bar,
.progress-bar.active {
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
	background-color: #4FC2C4;
}
.progress-striped .progress-bar-success {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
	background-color: #007dae;
}
.progress-striped .progress-bar-info {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
	background-color: #FF7D34;
}
.progress-striped .progress-bar-warning {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
	background-color: #7D50B2;
}
.progress-striped .progress-bar-danger {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.media {
	margin-top: 15px;
}
.media:first-child {
	margin-top: 0;
}
.media,
.media-body {
	zoom: 1;
	overflow: hidden;
}
.media-body {
	width: 10000px;
}
.media-object {
	display: block;
}
.media-object.img-thumbnail {
	max-width: none;
}
.media-right,
.media > .pull-right {
	padding-left: 10px;
}
.media-left,
.media > .pull-left {
	padding-right: 10px;
}
.media-left,
.media-right,
.media-body {
	display: table-cell;
	vertical-align: top;
}
.media-middle {
	vertical-align: middle;
}
.media-bottom {
	vertical-align: bottom;
}
.media-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.media-list {
	padding-left: 0;
	list-style: none;
}
.list-group {
	margin-bottom: 0px;
	padding-left: 0;
	overflow:auto;
}
.list-group-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	margin-bottom: -1px;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	overflow:auto;
}
.list-group-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
a.list-group-item,
button.list-group-item {
	color: #555555;
	text-decoration: none;
}
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
	color: #333333;
}
a.list-group-item:hover,
button.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:focus {
	text-decoration: none;
	color: #555555;
	background-color: #f5f5f5;
}
button.list-group-item {
	width: 100%;
	text-align: left;
}
.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
	background-color: #eeeeee;
	color: #777777;
	cursor: not-allowed;
}
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
	color: inherit;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
	color: #777777;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
	z-index: 2;
	color: #ffffff;
	background-color: #00386d;
	border-color: #00386d;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading > .small {
	color: inherit;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
	color: #c7ddef;
}
.list-group-item-success {
	color: #3c763d;
	background-color: #dff0d8;
}
a.list-group-item-success,
button.list-group-item-success {
	color: #3c763d;
}
a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
	color: inherit;
}
a.list-group-item-success:hover,
button.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:focus {
	color: #3c763d;
	background-color: #d0e9c6;
}
a.list-group-item-success.active,
button.list-group-item-success.active,
a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active:focus {
	color: #fff;
	background-color: #3c763d;
	border-color: #3c763d;
}
.list-group-item-info {
	color: #31708f;
	background-color: #d9edf7;
}
a.list-group-item-info,
button.list-group-item-info {
	color: #31708f;
}
a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
	color: inherit;
}
a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
	color: #31708f;
	background-color: #c4e3f3;
}
a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
	color: #fff;
	background-color: #31708f;
	border-color: #31708f;
}
.list-group-item-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
}
a.list-group-item-warning,
button.list-group-item-warning {
	color: #8a6d3b;
}
a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
	color: inherit;
}
a.list-group-item-warning:hover,
button.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:focus {
	color: #8a6d3b;
	background-color: #faf2cc;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active:focus {
	color: #fff;
	background-color: #8a6d3b;
	border-color: #8a6d3b;
}
.list-group-item-danger {
	color: #a94442;
	background-color: #f2dede;
}
a.list-group-item-danger,
button.list-group-item-danger {
	color: #a94442;
}
a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
	color: inherit;
}
a.list-group-item-danger:hover,
button.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:focus {
	color: #a94442;
	background-color: #ebcccc;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active:focus {
	color: #fff;
	background-color: #a94442;
	border-color: #a94442;
}
.list-group-item-heading {
	margin-top: 0;
	margin-bottom: 5px;
}
.list-group-item-text {
	margin-bottom: 0;
	line-height: 1.3;
}
.panel {
	margin-bottom: 20px;
	background-color: #ffffff;
	border: 1px solid transparent;
	border-radius: 0px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
	padding: 15px;
}
.panel-heading {
	padding: 10px 15px;
	border-bottom: 1px solid transparent;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.panel-heading > .dropdown .dropdown-toggle {
	color: inherit;
}
.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
	color: inherit;
}
.panel-footer {
	padding: 10px 15px;
	background-color: #f5f5f5;
	border-top: 1px solid #dddddd;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.panel > .list-group,
.panel > .panel-collapse > .list-group {
	margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
	border-width: 1px 0;
	border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
	border-top: 0;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
	border-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
	border-top-width: 0;
}
.list-group + .panel-footer {
	border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
	margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
	padding-left: 15px;
	padding-right: 15px;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
	border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
	border-top-right-radius: 3px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
	border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
	border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
	border-top: 1px solid #dddddd;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
	border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
	border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
	border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
	border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
	border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
	border-bottom: 0;
}
.panel > .table-responsive {
	border: 0;
	margin-bottom: 0;
}
.panel-group {
	margin-bottom: 20px;
}
.panel-group .panel {
	margin-bottom: 0;
	border-radius: 0px;
}
.panel-group .panel + .panel {
	margin-top: 5px;
}
.panel-group .panel-heading {
	border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
	border-top: 1px solid #dddddd;
}
.panel-group .panel-footer {
	border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
	border-bottom: 1px solid #dddddd;
}
.panel-default {
	border-color: #dddddd;
}
.panel-default > .panel-heading {
	color: #333333;
	background-color: #f5f5f5;
	border-color: #dddddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #dddddd;
}
.panel-default > .panel-heading .badge {
	color: #f5f5f5;
	background-color: #333333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #dddddd;
}
.panel-primary {
	border-color: #00386d;
}
.panel-primary > .panel-heading {
	color: #ffffff;
	background-color: #00386d;
	border-color: #00386d;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #00386d;
}
.panel-primary > .panel-heading .badge {
	color: #00386d;
	background-color: #ffffff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #00386d;
}
.panel-success {
	border-color: #d6e9c6;
}
.panel-success > .panel-heading {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
	color: #dff0d8;
	background-color: #3c763d;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #d6e9c6;
}
.panel-info {
	border-color: #bce8f1;
}
.panel-info > .panel-heading {
	color: #31708f;
	background-color: #d9edf7;
	border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
	color: #d9edf7;
	background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #bce8f1;
}
.panel-warning {
	border-color: #faebcc;
}
.panel-warning > .panel-heading {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
	color: #fcf8e3;
	background-color: #8a6d3b;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #faebcc;
}
.panel-danger {
	border-color: #ebccd1;
}
.panel-danger > .panel-heading {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #ebccd1;
}
.panel-danger > .panel-heading .badge {
	color: #f2dede;
	background-color: #a94442;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #ebccd1;
}
.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border: 0;
}
.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
	padding-bottom: 75%;
}
.well {
	min-height: 20px;
	padding: 19px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 0px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
	border-color: #ddd;
	border-color: rgba(0, 0, 0, 0.15);
}
.well-lg {
	padding: 24px;
	border-radius: 0px;
}
.well-sm {
	padding: 9px;
	border-radius: 0px;
}
.close {
	float: right;
	font-size: 21px;
	font-weight: bold;
	line-height: 1;
	color: #000000;
	text-shadow: 0 1px 0 #ffffff;
	opacity: 0.2;
	filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
	color: #000000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}
.clearfix:before,
.clearfix:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after {
	content: " ";
	display: table;
}
.clearfix:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.pager:after,
.panel-body:after {
	clear: both;
}
.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}
.hide {
	display: none !important;
}
.show {
	display: block !important;
}
.invisible {
	visibility: hidden;
}
.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.hidden {
	display: none !important;
}
.affix {
	position: fixed;
}
@-ms-viewport {
	width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}
@media (max-width: 767px) {
	.visible-xs {
	display: block !important;
	}
	table.visible-xs {
	display: table !important;
	}
	tr.visible-xs {
	display: table-row !important;
	}
	th.visible-xs,
	td.visible-xs {
	display: table-cell !important;
	}
}
@media (max-width: 767px) {
	.visible-xs-block {
	display: block !important;
	}
}
@media (max-width: 767px) {
	.visible-xs-inline {
	display: inline !important;
	}
}
@media (max-width: 767px) {
	.visible-xs-inline-block {
	display: inline-block !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm {
	display: block !important;
	}
	table.visible-sm {
	display: table !important;
	}
	tr.visible-sm {
	display: table-row !important;
	}
	th.visible-sm,
	td.visible-sm {
	display: table-cell !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-block {
	display: block !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline {
	display: inline !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.visible-sm-inline-block {
	display: inline-block !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md {
	display: block !important;
	}
	table.visible-md {
	display: table !important;
	}
	tr.visible-md {
	display: table-row !important;
	}
	th.visible-md,
	td.visible-md {
	display: table-cell !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-block {
	display: block !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline {
	display: inline !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.visible-md-inline-block {
	display: inline-block !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg {
	display: block !important;
	}
	table.visible-lg {
	display: table !important;
	}
	tr.visible-lg {
	display: table-row !important;
	}
	th.visible-lg,
	td.visible-lg {
	display: table-cell !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg-block {
	display: block !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg-inline {
	display: inline !important;
	}
}
@media (min-width: 1200px) {
	.visible-lg-inline-block {
	display: inline-block !important;
	}
}
@media (max-width: 767px) {
	.hidden-xs {
	display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-sm {
	display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-md {
	display: none !important;
	}
}
@media (min-width: 1200px) {
	.hidden-lg {
	display: none !important;
	}
}
.visible-print {
	display: none !important;
}
@media print {
	.visible-print {
	display: block !important;
	}
	table.visible-print {
	display: table !important;
	}
	tr.visible-print {
	display: table-row !important;
	}
	th.visible-print,
	td.visible-print {
	display: table-cell !important;
	}
}
.visible-print-block {
	display: none !important;
}
@media print {
	.visible-print-block {
	display: block !important;
	}
}
.visible-print-inline {
	display: none !important;
}
@media print {
	.visible-print-inline {
	display: inline !important;
	}
}
.visible-print-inline-block {
	display: none !important;
}
@media print {
	.visible-print-inline-block {
	display: inline-block !important;
	}
}
@media print {
	.hidden-print {
	display: none !important;
	}
}


/**
	bootstrap related styles
**/
/**
 * button
 * --------------------------------------------------
 */
.btn {
	color: white;
}
.btn, .btn.disabled, .btn[disabled] {
	background-color: #aab2bd;
	border-color: #aab2bd;
	color: #fff !important;
}
.btn:hover, .btn:focus, .btn:active, .btn.active {
	color: white;
	background-color: #ccd1d9;
	border-color: #ccd1d9;
	outline: none !important;
}
.btn:active, .btn.active {
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
	 -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
}
.btn.disabled, .btn[disabled] {
	filter: alpha(opacity=45);
	opacity: .45;
}
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.disabled, .btn-link[disabled] {
	color: #00386d;
	background-color: transparent;
	border-color: transparent;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.btn-link:hover, .btn-link:focus {
	text-decoration: underline;
}
.btn-default {
	color: #00386d !important;
	border-color: #aab2bd !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
	background-color: #ccd1d9;
	border-color: #ccd1d9;
}
.btn-default, .btn-default.disabled, .btn-default[disabled] {
	background-color: #e6e9ed;
}
a.btn-default {
	text-decoration: none;
}
.open .dropdown-toggle.btn-default {
	background-color: #ccd1d9;
	border-color: #ccd1d9;
}
.btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
	background-color: #00386d;
	border-color: #00386d;
}
a.btn-primary {
	text-decoration: none;
}
.btn-primary:hover, .btn-primary:focus {
	background-color: #4fc1e9;
	border-color: #4fc1e9;
}
.open .dropdown-toggle.btn-primary {
	background-color: #4fc1e9;
	border-color: #4fc1e9;
}
.btn-info, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
	background-color:	#007dae;
	border-color:	#007dae;
	text-decoration: none;
}
a.btn-info {
	text-decoration: none;
}
.btn-info:hover, .btn-info:focus {
	background-color: #48cfad;
	border-color: #48cfad;
}
.open .dropdown-toggle.btn-info {
	background-color: #48cfad;
	border-color: #48cfad;
}
.btn-success, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
	background-color: #4FC2C4;
	border-color: #4FC2C4;
}
a.btn-success {
	text-decoration: none;
}
.btn-success:hover, .btn-success:focus {
	background-color: #a0d468;
	border-color: #a0d468;
}
.open .dropdown-toggle.btn-success {
	background-color: #a0d468;
	border-color: #a0d468;
}
.btn-warning, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
	background-color: #FF7D34;
	border-color: #FF7D34;
}
.btn-warning:hover, .btn-warning:focus {
	background-color: #ffce54;
	border-color: #ffce54;
}
.open .dropdown-toggle.btn-warning {
	background-color: #ffce54;
	border-color: #ffce54;
}
.btn-danger, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled],
.btn-danger .open .dropdown-toggle.btn {
	background-color: #7D50B2;
	border-color: #7D50B2;
}
.btn-danger:hover, .btn-danger:focus {
	background-color: #ed5565;
	border-color: #ed5565;
}
.open .dropdown-toggle.btn-danger {
	background-color: #ed5565;
	border-color: #ed5565;
}

/**
 * button-group
 * --------------------------------------------------
 */
.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
	 -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, .125);
}
.btn-group .btn {
	border-left-color: #96a0ad;
}
.btn-group.open .btn-default.dropdown-toggle, .btn-group .btn-default:focus, .btn-group .btn-default:active, .btn-group .btn-default.active {
	color: white;
}
.btn-group .btn-primary, .btn-group .btn-primary:focus, .btn-group .btn-primary:active, .btn-group .btn-primary.active {
	border-left-color: #269ecb;
}
.btn-group .btn-success, .btn-group .btn-success:focus, .btn-group .btn-success:active, .btn-group .btn-success.active {
	border-left-color: #7ab03f;
}
.btn-group .btn-warning, .btn-group .btn-warning:focus, .btn-group .btn-warning:active, .btn-group .btn-warning.active {
	border-left-color: #efa50b;
}
.btn-group .btn-danger, .btn-group .btn-danger:focus, .btn-group .btn-danger:active, .btn-group .btn-danger.active {
	border-left-color: #d1293a;
}
.btn-group .btn-info, .btn-group .btn-info:focus, .btn-group .btn-info:active, .btn-group .btn-info.active {
	border-left-color: #2fa084;
}
.btn-group .btn:first-child, .btn-group .btn-primary:first-child, .btn-group .btn-success:first-child, .btn-group .btn-warning:first-child, .btn-group .btn-danger:first-child, .btn-group .btn-info:first-child {
	border-left-color: transparent;
}

.btn-group-vertical .btn, .btn-group-vertical .btn-group .btn-primary {
	border-top-color: #96a0ad !important;
}
.btn-group-vertical .btn-primary, .btn-group-vertical .btn-primary:focus, .btn-group-vertical .btn-primary:active, .btn-group-vertical .btn-primary.active, .btn-group-vertical .btn-group .btn-primary {
	border-top-color: #27a1cc !important;
}
.btn-group-vertical .btn-success, .btn-group-vertical .btn-success:focus, .btn-group-vertical .btn-success:active, .btn-group-vertical .btn-success.active, .btn-group-vertical .btn-group .btn-success {
	border-top-color: #7ab03f !important;
}
.btn-group-vertical .btn-warning, .btn-group-vertical .btn-warning:focus, .btn-group-vertical .btn-warning:active, .btn-group-vertical .btn-warning.active, .btn-group-vertical .btn-group .btn-warning {
	border-top-color: #efa50b !important;
}
.btn-group-vertical .btn-danger, .btn-group-vertical .btn-danger:focus, .btn-group-vertical .btn-danger:active, .btn-group-vertical .btn-danger.active, .btn-group-vertical .btn-group .btn-danger {
	border-top-color: #d1293a !important;
}
.btn-group-vertical .btn-info, .btn-group-vertical .btn-info:focus, .btn-group-vertical .btn-info:active, .btn-group-vertical .btn-info.active, .btn-group-vertical .btn-group .btn-info {
	border-top-color: #2fa084 !important;
}
.btn-group-vertical .btn:not(.btn-default):first-child, .btn-group-vertical .btn-primary:first-child, .btn-group-vertical .btn-success:first-child, .btn-group-vertical .btn-warning:first-child, .btn-group-vertical .btn-danger:first-child, .btn-group-vertical .btn-info:first-child {
	border-top: none;
}

/**
 * labels and badges
 * --------------------------------------------------
 */
.label,
.badge {
	background-color: #aab2bd;
	color: #fff!important;
	text-transform: capitalize;
}

.label-default,
.badge-default {
	color: #434a54!important;
	background-color: white;
	border: 1px solid #aab2bd;
}

.label-primary,
.badge-primary {
	background-color: #00386d;
	border-color: #00386d;
	color: #fff!important;
}

.label-success,
.badge-success {
	background-color: #4FC2C4;
	border-color: #4FC2C4;
	color: #fff!important;
}

.label-danger,
.badge-danger {
	background-color: #7D50B2;
	border-color: #7D50B2;
	color: #fff!important;
}

.label-warning,
.badge-warning {
	background-color: #FF7D34;
	border-color: #FF7D34;
	color: #fff!important;
}

.label-info,
.badge-info {
	background-color:	#007dae;
	border-color:	#007dae;
	color: #fff!important;
}

/**
 * tooltip
 * --------------------------------------------------
 */
.tooltip-inner {
	color: white;
	background-color: #434a54;
}
.tooltip.top .tooltip-arrow, .tooltip.top-left .tooltip-arrow, .tooltip.top-right .tooltip-arrow {
	border-top-color: #434a54;
}
.tooltip.right .tooltip-arrow {
	border-right-color: #434a54;
}
.tooltip.left .tooltip-arrow {
	border-left-color: #434a54;
}
.tooltip.bottom .tooltip-arrow, .tooltip.bottom-left .tooltip-arrow, .tooltip.bottom-right .tooltip-arrow {
	border-bottom-color: #434a54;
}

/**
 * popover
 * --------------------------------------------------
 */
.popover {
	color: white;
	background-color: #434a54;
	border-color: #434a54;
}
.popover-title {
	padding-bottom: 0;
	font-weight: bold;
	color: #aab2bd;
	background-color: transparent;
	border-bottom: none;
}
.popover.top .arrow, .popover.top .arrow:after {
	border-top-color: #434a54;
}
.popover.right .arrow, .popover.right .arrow:after {
	border-right-color: #434a54;
}
.popover.bottom .arrow, .popover.bottom .arrow:after {
	border-bottom-color: #434a54;
}
.popover.left .arrow, .popover.left .arrow:after {
	border-left-color: #434a54;
}

/**
 * progress
 * --------------------------------------------------
 */
.progress {
	background-color: #e6e9ed;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.progress-bar {
	background-color: #00386d;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.progress-bar-success {
	background-color: #4FC2C4;
}
.progress-bar-info {
	background-color:	#007dae;
}
.progress-bar-warning {
	background-color: #FF7D34;
}
.progress-bar-danger {
	background-color: #7D50B2;
}

/**
 * breadcrumb
 * --------------------------------------------------
 */
.breadcrumb {
	color: #434a54;
	background-color: #e6e9ed;
}
.breadcrumb > .active {
	color: #434a54;
}
.breadcrumb a {
	color: #00386d;
}

.breadcrumb-arrow {
	height: 36px;
	padding: 0;
	line-height: 36px;
	list-style: none;
	background-color: #e6e9ed;
}
.breadcrumb-arrow li:first-child a {
			border-radius: 0px 0 0 0px;

	-webkit-border-radius: 0px 0 0 0px;
	 -moz-border-radius: 0px 0 0 0px;
}
.breadcrumb-arrow li, .breadcrumb-arrow li a, .breadcrumb-arrow li span {
	display: inline-block;
	vertical-align: top;
}
.breadcrumb-arrow li:not(:first-child) {
	margin-left: -5px;
}
.breadcrumb-arrow li + li:before {
	padding: 0;
	content: "";
}
.breadcrumb-arrow li span {
	padding: 0 10px;
}
.breadcrumb-arrow li a, .breadcrumb-arrow li:not(:first-child) span {
	height: 36px;
	padding: 0 10px 0 25px;
	line-height: 36px;
}
.breadcrumb-arrow li:first-child a {
	padding: 0 10px;
}
.breadcrumb-arrow li a {
	position: relative;
	color: white;
	text-decoration: none;
	background-color: #00386d;
	border: 1px solid #00386d;
}
.breadcrumb-arrow li:first-child a {
	padding-left: 10px;
}
.breadcrumb-arrow li a:before, .breadcrumb-arrow li a:after {
	position: absolute;
	top: -1px;
	width: 0;
	height: 0;
	content: '';
	border-top: 18px solid transparent;
	border-bottom: 18px solid transparent;
}
.breadcrumb-arrow li a:before {
	right: -10px;
	z-index: 3;
	border-left-color: #00386d;
	border-left-style: solid;
	border-left-width: 11px;
}
.breadcrumb-arrow li a:after {
	right: -11px;
	z-index: 2;
	border-left: 11px solid #2494be;
}
.breadcrumb-arrow li a:hover, .breadcrumb-arrow li a:focus {
	background-color: #4fc1e9;
	border: 1px solid #4fc1e9;
}
.breadcrumb-arrow li a:hover:before, .breadcrumb-arrow li a:focus:before {
	border-left-color: #4fc1e9;
}
.breadcrumb-arrow li a:active {
	background-color: #2494be;
	border: 1px solid #2494be;
}
.breadcrumb-arrow li a:active:before, .breadcrumb-arrow li a:active:after {
	border-left-color: #2494be;
}
.breadcrumb-arrow li span {
	color: #434a54;
}

/**
 * pagination
 * --------------------------------------------------
 */
.pagination > li > a, .pagination > li > span {
	color: #434a54;
	background-color: white;
	border-color: #ccd1d9;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
	color: white;
	background-color: #ccd1d9;
	border-color: #ccd1d9;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
	color: white;
	background-color: #4FC2C4;
	border-color: #4FC2C4;
}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
	color: #e6e9ed;
	background-color: white;
	border-color: #ccd1d9;
}

/**
 * pager
 * --------------------------------------------------
 */
.pager li > a, .pager li > span {
	color: white;
	background-color: #0091c0;
	border-color: #0091c0;
}
.pager li > a:hover, .pager li > a:focus {
	background-color: #a0d468;
	border-color: #a0d468;
}
.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
	color: #e6e9ed;
	background-color: white;
	border-color: #e6e9ed;
}

/**
 * form
 * --------------------------------------------------
 */
.form-control {
	color: #434a54;
	border-color: #aab2bd;
}
.form-control, .form-control:focus {
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.form-control:focus {
	border-color: #00386d;
}
.form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
	color: #e6e9ed;
}
.form-control.disabled, .form-control[disabled] {
	background-color: #e6e9ed;
	border-color: #e6e9ed;
}

.input-group-btn .btn + .btn {
	border-color: #96a0ad;
	border-style: solid;
	border-width: 1px;
}
.input-group-btn .btn + .btn.btn-default {
	border-color: #ededed;
}
.input-group-btn .btn + .btn.btn-primary {
	border-color: #269ecb;
}
.input-group-btn .btn + .btn.btn-info {
	border-color: #2fa084;
}
.input-group-btn .btn + .btn.btn-success {
	border-color: #7ab03f;
}
.input-group-btn .btn + .btn.btn-warning {
	border-color: #f4af20;
}
.input-group-btn .btn + .btn.btn-danger {
	border-color: #d1293a;
}

.input-group-addon {
	color: white;
	background-color: #aab2bd;
	border-color: #96a0ad;
}
.input-group-addon .radio, .input-group-addon .checkbox {
	margin: -3px 0 -4px !important;
}

.form-search .search-query, .form-search .search-query:first-child, .form-search .search-query:last-child {
	padding: 0 17px;
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.input-group .form-control:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

	-webkit-border-top-left-radius: 0;
	-moz-border-radius-topleft: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
}
.input-group .form-control:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

	-webkit-border-top-right-radius: 0;
	-moz-border-radius-topright: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
}
.form-search .btn {
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}

.search-only {
	position: relative;
}
.search-only .search-icon {
	position: absolute;
	top: 2px;
	left: 8.5px;
	z-index: 20;
	width: 30px;
	font-size: 17px;
	line-height: 30px;
	color: #e6e9ed;
	text-align: center;
}
.search-only .form-control:last-child {
	padding-left: 40px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline {
	color: #4FC2C4;
}
.has-success .form-control {
	border-color: #4FC2C4;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-success .form-control:focus {
	border-color: #4FC2C4;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-success .input-group-addon {
	background-color: #4FC2C4;
	border-color: #4FC2C4;
}
.has-success .form-control-feedback {
	color: #4FC2C4;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline {
	color: #FF7D34;
}
.has-warning .form-control {
	border-color: #FF7D34;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-warning .form-control:focus {
	border-color: #FF7D34;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-warning .input-group-addon {
	background-color: #FF7D34;
	border-color: #FF7D34;
}
.has-warning .form-control-feedback {
	color: #FF7D34;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline {
	color: #7D50B2;
}
.has-error .form-control {
	border-color: #7D50B2;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-error .form-control:focus {
	border-color: #7D50B2;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.has-error .input-group-addon {
	background-color: #7D50B2;
	border-color: #7D50B2;
}
.has-error .form-control-feedback {
	color: #7D50B2;
}

/**
 * stepper
 * --------------------------------------------------
 */
.stepper .stepper-input {
	overflow: hidden;

	-moz-appearance: textfield;
}
.stepper .stepper-input::-webkit-inner-spin-button, .stepper .stepper-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.stepper .stepper-arrow {
	position: absolute;
	right: 15px;
	display: block;
	width: 20px;
	height: 50%;
	text-indent: -99999px;
	cursor: pointer;
	background-color: #00386d;
}
.stepper .stepper-arrow:hover, .stepper .stepper-arrow:active {
	background-color: #4fc1e9;
}
.stepper .up {
	top: 0;
	border: 1px solid #269ecb;
			border-top-right-radius: 3px;

	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topright: 3px;
}
.stepper .down {
	bottom: 0;
			border-bottom-right-radius: 3px;

	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-bottomright: 3px;
}
.stepper .up::before, .stepper .down::before {
	position: absolute;
	width: 0;
	height: 0;
	content: "";
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}
.stepper .up::before {
	top: 5px;
	left: 5px;
	border-bottom: 4px solid white;
}
.stepper .down:before {
	bottom: 5px;
	left: 6px;
	border-top: 4px solid white;
}
.stepper.disabled .stepper-arrow {
	background-color: #00386d;
	filter: alpha(opacity=45);
	opacity: .45;
}

.selecter {
	position: relative;
	z-index: 1;
	display: block;
	max-width: 100%;
	outline: none;
	/* Open */
	/* 'Cover' Positioning */
	/* 'Bottom' Positioning */
	/* 'Bottom' + 'Cover' Positioning */
	/* Multiple Select */
	/* 'Disabled' State */
	/* Scroller Support */
}
.selecter .selecter-element {
	position: absolute;
	left: 0;
	z-index: 0;
	display: none;
	width: 100%;
	height: 100%;
	filter: alpha(opacity=0);
	opacity: 0;

	/*left: -999999px;*/
}
.selecter .selecter-element, .selecter .selecter-element:focus {
	outline: none;

	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}
.selecter .selecter-selected {
	position: relative;
	z-index: 2;
	display: block;
	padding: 6px 10px;
	overflow: hidden;
	text-overflow: clip;
	cursor: pointer;
	background-color: white;
	border: 1px solid #aab2bd;
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.selecter .selecter-selected:after {
	position: absolute;
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	content: "";
	border-top: 4px solid black;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}
.selecter .selecter-options {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 50;
	display: none;
	width: 100%;
	max-height: 260px;
	overflow: auto;
	overflow-x: hidden;
	background-color: white;
	border: 1px solid #aab2bd;
	border-width: 0 1px 1px;
			border-radius: 0 0 0px 0px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	 -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
			box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

	-webkit-border-radius: 0 0 0px 0px;
	 -moz-border-radius: 0 0 0px 0px;
	*width: auto;
}
.selecter .selecter-group {
	display: block;
	padding: 5px 10px 4px;
	font-size: 11px;
	color: #aab2bd;
	text-transform: uppercase;
	background-color: #f5f7fa;
	border-bottom: 1px solid #e6e9ed;
}
.selecter .selecter-item {
	display: block;
	width: 100%;
	padding: 6px 10px;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	background-color: white;
	border-bottom: 1px solid #e6e9ed;
}
.selecter .selecter-item.selected {
	color: white;
	background-color: #00386d;
	border-bottom-color: #4fc1e9;
}
.selecter .selecter-item.disabled {
	color: #aab2bd;
	cursor: default;
}
.selecter .selecter-item:first-child {
			border-radius: 0;

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.selecter .selecter-item:last-child {
	border-bottom: 0;
			border-radius: 0 0 0px 0px;

	-webkit-border-radius: 0 0 0px 0px;
	 -moz-border-radius: 0 0 0px 0px;
}
.selecter .selecter-item:hover {
	background-color: #e6e9ed;
}
.selecter .selecter-item.selected:hover {
	background-color: #00386d;
}
.selecter .selecter-item.disabled:hover, .selecter:hover .selecter-selected, .selecter.disabled .selecter-item:hover {
	background-color: white;
}
.selecter.open {
	z-index: 3;
	outline: 0;
}
.selecter.open .selecter-selected {
	z-index: 51;
	border: 1px solid #00386d;
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.selecter.open .selecter-selected, .selecter.focus .selecter-selected {
	background-color: white;
}
.selecter.cover .selecter-options {
	top: 0;
	border-width: 1px;
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: v;
}
.selecter.cover .selecter-options .selecter-item.first {
			border-radius: 0px 0px 0px 0px;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.selecter.cover.open .selecter-selected {
	z-index: 49;
			border-radius:	0px 0px 0 0;

	-webkit-border-radius:	0px 0px 0 0;
	 -moz-border-radius:	0px 0px 0 0;
}
.selecter.bottom .selecter-options {
	top: auto;
	bottom: 100%;
	border-width: 1px 1px 0;
}
.selecter.bottom .selecter-item:last-child {
	border: none;
			border-radius: 0;

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.selecter.bottom.open .selecter-selected {
			border-radius:	0px 0px 0 0;

	-webkit-border-radius:	0px 0px 0 0;
	 -moz-border-radius:	0px 0px 0 0;
}
.selecter.bottom.open .selecter-options {
			border-radius:	0px 0px 0 0;

	-webkit-border-radius:	0px 0px 0 0;
	 -moz-border-radius:	0px 0px 0 0;
}
.selecter.bottom.cover .selecter-options {
	top: auto;
	bottom: 0;
}
.selecter.bottom.cover.open .selecter-selected, .selecter.bottom.cover.open .selecter-options {
			border-radius:	0px 0px 0 0;

	-webkit-border-radius:	0px 0px 0 0;
	 -moz-border-radius:	0px 0px 0 0;
}
.selecter.multiple .selecter-options {
	position: static;
	display: block;
	width: 100%;
	border-width: 1px;
	box-shadow: none;
			border-radius:	0px 0px 0 0;

	-webkit-border-radius:	0px 0px 0 0;
	 -moz-border-radius:	0px 0px 0 0;
}
.selecter.disabled .selecter-selected {
	color: #aab2bd;
	cursor: default;
	background-color: #e6e9ed;
	border-color: #e6e9ed;
}
.selecter.disabled .selecter-options {
	background-color: #e6e9ed;
	border-color: #e6e9ed;
}
.selecter.disabled .selecter-group, .selecter.disabled .selecter-item {
	color: #aab2bd;
	cursor: default;
	background-color: #e6e9ed;
	border-color: #e6e9ed;
}
.selecter.disabled .selecter-item.selected {
	color: white;
	background-color: #00386d;
	filter: alpha(opacity=45);
	opacity: .45;
}
.selecter .selecter-options.scroller {
	overflow: hidden;
}
.selecter .selecter-options.scroller .scroller-content {
	max-height: 260px;
	padding: 0;
}

/**
 * checkbox and radio
 * --------------------------------------------------
 */
.checkbox, .radio {
	padding-left: 0;
	margin-top: 0;
}

.checkbox label, .radio label {
	position: relative;
	top: 2px;
	padding-left: 5px;
}

.icheckbox_flat,
.iradio_flat {
	display: inline-block;
	width: 20px;
	height: 20px;
	padding: 0 !important;
	margin: 0;
	vertical-align: middle;
	cursor: pointer;
	background: url("./_media/images/check_flat/default.png") no-repeat;
	border: none;

	*display: inline;
}

.icheckbox_flat {
	background-position: 0 0;
}
.icheckbox_flat.checked {
	background-position: -22px 0;
}
.icheckbox_flat.disabled {
	cursor: default;
	background-position: -44px 0;
}
.icheckbox_flat.checked.disabled {
	background-position: -66px 0;
}

.iradio_flat {
	background-position: -88px 0;
}
.iradio_flat.checked {
	background-position: -110px 0;
}
.iradio_flat.disabled {
	cursor: default;
	background-position: -132px 0;
}
.iradio_flat.checked.disabled {
	background-position: -154px 0;
}

/**
 * toggle
 * --------------------------------------------------
 */
.toggle {
	height: 32px;
}
.toggle input[type="checkbox"], .toggle input[type="radio"] {
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
	text-indent: -100000px;
	filter: alpha(opacity=0);
	opacity: 0;
}
.toggle .handle {
	position: relative;
	top: -20px;
	left: 0;
	display: block;
	width: 50px;
	height: 32px;
	background-color: white;
			border-radius: 19px;
	-webkit-box-shadow: inset 0 0 0 1px #b8bfc8;
	 -moz-box-shadow: inset 0 0 0 1px #b8bfc8;
			box-shadow: inset 0 0 0 1px #b8bfc8;

	-webkit-border-radius: 19px;
	 -moz-border-radius: 19px;
}
.toggle .handle:before, .toggle .handle:after {
	position: absolute;
	top: 1px;
	left: 1px;
	display: block;
	width: 30px;
	height: 30px;
	content: "";
	background-color: white;
			border-radius: 30px;
	-webkit-box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
	 -moz-box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
			box-shadow: inset 0 0 0 1px #b8bfc8, 1px 1px 1px #c7ccd3;
	-webkit-transition: all .25s ease-in-out;
	 -moz-transition: all .25s ease-in-out;
			transition: all .25s ease-in-out;

	-webkit-border-radius: 30px;
	 -moz-border-radius: 30px;
}
.toggle input[type="checkbox"]:disabled + .handle, .toggle input[type="radio"]:disabled + .handle, .toggle input[type="checkbox"]:disabled + .handle:before, .toggle input[type="radio"]:disabled + .handle:before, .toggle input[type="checkbox"]:disabled + .handle:after, .toggle input[type="radio"]:disabled + .handle:after {
	background-color: #e6e9ed;
	filter: alpha(opacity=60);
	opacity: .6;
}
.toggle input[type="checkbox"]:checked + .handle:before, .toggle input[type="radio"]:checked + .handle:before {
	width: 50px;
	background-color: #a0d468;
}
.toggle input[type="checkbox"]:checked + .handle:after, .toggle input[type="radio"]:checked + .handle:after {
	left: 20px;
	-webkit-box-shadow: inset 0 0 0 1px #f5f7fa, 1px 1px 1px #c7ccd3;
	 -moz-box-shadow: inset 0 0 0 1px #f5f7fa, 1px 1px 1px #c7ccd3;
			box-shadow: inset 0 0 0 1px #f5f7fa, 1px 1px 1px #c7ccd3;
}

/**
 * calendar
 * --------------------------------------------------
 */
.calendar {
	padding: 20px;
	color: white;
	background-color: #fd9883;
			border-radius: 0;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.calendar .unit {
	float: left;
	width: 14.28%;
	text-align: center;
}
.calendar .years .prev {
	text-align: left;
}
.calendar .years .next {
	text-align: right;
}
.calendar .years .prev em, .calendar .years .next em {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 34px;
	cursor: pointer;
	border: 1px solid white;
			border-radius: 50%;

	-webkit-border-radius: 50%;
	 -moz-border-radius: 50%;
}
.calendar .years .prev em:before, .calendar .years .next em:before {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	margin-top: 6px;
	font-size: 0;
	content: "";
	border-style: solid;
	border-width: 7px;
}
.calendar .years .prev em:before {
	top: 3px;
	left: 4px;
	border-color: transparent white transparent transparent;
}
.calendar .years .next em:before {
	top: 3px;
	left: 13px;
	border-color: transparent transparent transparent white;
}
.calendar .years .prev em:hover, .calendar .years .next em:hover, .calendar .years .prev em:active, .calendar .years .next em:active {
	border-color: #e9573f;
}
.calendar .years .prev em:hover:before, .calendar .years .prev em:active:before {
	border-color: transparent #e9573f transparent transparent;
}
.calendar .years .next em:hover:before, .calendar .years .next em:active:before {
	border-color: transparent transparent transparent #e9573f;
}
.calendar .years .monyear {
	float: left;
	width: 71.42%;
	height: 34px;
	line-height: 34px;
	text-align: center;
}
.calendar .days {
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #ee7f6d;
}
.calendar .days .unit {
	height: 34px;
	margin-bottom: 3px;
	line-height: 34px;
	text-align: center;
}
.calendar .days .unit b {
	width: 34px;
	height: 34px;
	font-weight: normal;
			border-radius: 50%;

	-webkit-border-radius: 50%;
	 -moz-border-radius: 50%;
}
.calendar .days .unit:hover b, .calendar .days .unit:active b, .calendar .days .unit.active b {
	display: inline-block;
	color: #e9573f;
	cursor: pointer;
	background-color: white;
	-webkit-transition: all .2s ease-in-out;
	 -moz-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
}
.calendar .days .unit.older b {
	width: auto;
	height: auto;
	color: #e9573f;
	cursor: default;
	background-color: transparent;
}

/**
 * pricing
 * --------------------------------------------------
 */
.pricing ul {
	padding: 0;
	list-style: none;
}
.pricing .unit {
	position: relative;
	display: inline-block;
	min-width: 250px;
	text-align: center;
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.pricing .unit.active {
	top: 5px;
	z-index: 1;
	margin-right: -36px;
	margin-left: -36px;
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
	 -moz-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
			box-shadow: 0 0 8px rgba(0, 0, 0, .6);
}
.pricing .unit.active .price-title h3 {
	font-size: 40px;
}
@media screen and (max-width: 767px) {
	.pricing .unit {
	display: block;
	margin-bottom: 20px;
	}
	.pricing .unit.active {
	top: 0;
	margin-right: 0;
	margin-left: 0;
	}
	.pricing .unit.active .price-title h3 {
	font-size: 30px;
	}
}
.pricing .price-title {
	padding: 20px 20px 10px;
	color: #fff;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;

	-webkit-border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
}
.pricing .price-title h3, .pricing .price-title h3 > p {
	margin: 0;
}
.pricing .price-body {
	padding: 20px 20px 10px;
}
.pricing .price-body ul {
	padding-top: 10px;
}
.pricing .price-body li {
	margin-bottom: 10px;
}
.pricing .price-body h4 {
	margin: 0;
}
.pricing .price-foot {
	padding: 20px;
	background-color: #e6e9ed;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;

	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomleft: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
}
.pricing .price-primary .price-title {
	background-color: #4fc1e9;
}
.pricing .price-primary .price-body {
	background-color: #d7f1fa;
}
.pricing .price-primary .price-body ul {
	border-top: 1px solid #aae1f4;
}
.pricing .price-success .price-title {
	background-color: #a0d468;
}
.pricing .price-success .price-body {
	background-color: #ebf6df;
}
.pricing .price-success .price-body ul {
	border-top: 1px solid #d2ebb7;
}
.pricing .price-warning .price-title {
	background-color: #ffce54;
}
.pricing .price-warning .price-body {
	background-color: #fffaed;
}
.pricing .price-warning .price-body ul {
	border-top: 1px solid #ffebba;
}

/**
 * alert
 * --------------------------------------------------
 */
.alert h4 {
	margin-bottom: 10px;
	font-weight: bold;
}
.alert-dismissable .close {
	color: black!important;
}
.alert-default {
	background-color: #e6e9ed;
	border: #4fc1e9;
	color: #00386d;
}
.alert-primary {
	background-color: rgba(0, 57, 111, 0.2);
	border: #00386d;
	color: #00386d!important;
}
.alert-info {
	background-color: rgba(0, 125, 174, 0.2);
	border: #007dae;
	color: #007dae!important;
}

.alert-warning {
	background-color: rgba(255, 127, 52, 0.2);
	border: #FF7D34;
	color:#FF7D34!important;
}
.alert-danger {
	background-color: rgba(127, 81, 179, 0.2);
	border: #7D50B2;
	color: #7D50B2!important;
}
.alert-success {
	background-color: rgba(79, 195, 197, 0.2);
	border: #4FC2C4;
	color: #3c9e9f!important;
}
.alert .alert-link {
	text-decoration: underline;
	cursor: pointer;
	filter: alpha(opacity=65);
	opacity: .65;
}
.alert .alert-link:hover, .alert .alert-link:focus {
	filter: alpha(opacity=45);
	opacity: .45;
}
.alert .btn-link, .alert .btn-link:hover, .alert .btn-link:focus {
	color: black;
	filter: alpha(opacity=65);
	opacity: .65;
}
.alert .btn-link:focus, .alert .btn-link:hover {
	text-decoration: none;
	filter: alpha(opacity=40);
	opacity: .4;
}

/**
 * tab
 * --------------------------------------------------
 */
.nav-tabs {
	background-color: #e6e9ed;
	border-bottom: none;
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.nav-tabs > li {
	margin-bottom: 0;
	border-left: 1px solid #ccd1d9;
}
.nav-tabs > li:first-child {
	border-left: none;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #434a54;
	border: none;
			border-radius: 0;

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.nav-tabs > li:first-child > a {
			border-radius: 0px 0 0 0;

	-webkit-border-radius: 0px 0 0 0;
	 -moz-border-radius: 0px 0 0 0;
}
.nav-tabs > li > a:focus, .nav-tabs > li > a:hover {
	background-color: #f5f7fa;
	border: none;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
	background-color: white !important;
	border: none;
}
.nav-tabs .dropdown-toggle, .nav-tabs .dropdown-toggle:hover, .nav-tabs .dropdown-toggle:focus {
	color: #434a54;
}
.nav-tabs li.dropdown.open .dropdown-toggle {
	color: #434a54;
	background-color: #f5f7fa;
}
.nav-tabs li.dropdown.active.open .dropdown-toggle {
	color: #434a54;
}
.nav-tabs li.dropdown.active.open .dropdown-toggle .caret, .nav-tabs li.dropdown.active .dropdown-toggle .caret {
	border-top-color: #434a54;
	border-bottom-color: #434a54;
}
.nav-tabs li.dropdown.open .caret, .nav-tabs li.dropdown.open.active .caret, .nav-tabs li.dropdown.open a:hover .caret, .nav-tabs li.dropdown.open a:focus .caret, .nav-tabs .dropdown-toggle .caret, .nav-tabs .dropdown-toggle:hover .caret, .nav-tabs .dropdown-toggle:focus .caret {
	border-top-color: #434a54;
	border-bottom-color: #434a54;
}
.nav-tabs.nav-justified > li > a {
	margin-bottom: 0;
	text-align: center;
}
.nav-tabs.nav-justified > .dropdown .dropdown-menu {
	top: auto;
	left: auto;
}
.nav-tabs.nav-justified > li > a {
			border-radius: 0;

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.nav-tabs.nav-justified > li:first-child > a {
			border-radius: 0px 0 0 0;

	-webkit-border-radius: 0px 0 0 0;
	 -moz-border-radius: 0px 0 0 0;
}
.nav-tabs.nav-justified > li:last-child > a {
			border-radius: 0 0px 0 0;

	-webkit-border-radius: 0 0px 0 0;
	 -moz-border-radius: 0 0px 0 0;
}
.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
	border: none;
}
@media (min-width: 768px) {
	.nav-tabs.nav-justified > li > a {
	border-bottom: none;
			border-radius: 0;

	-webkit-border-radius: 0;
		 -moz-border-radius: 0;
	}
	.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
	border-bottom: none;
	}
}
.tab-content {
	padding: 10px;
}

.tabs-below .nav-tabs {
			border-radius: 0 0 4px 4px;

	-webkit-border-radius: 0 0 4px 4px;
	 -moz-border-radius: 0 0 4px 4px;
}
.tabs-below .nav-tabs > li:first-child > a {
			border-radius: 0 0 0 4px;

	-webkit-border-radius: 0 0 0 4px;
	 -moz-border-radius: 0 0 0 4px;
}
.tabs-below .nav-tabs.nav-justified > li:last-child > a {
			border-radius: 0 0 0px 0;

	-webkit-border-radius: 0 0 0px 0;
	 -moz-border-radius: 0 0 0px 0;
}

.tabs-left .nav-tabs > li,
.tabs-right .nav-tabs > li {
	float: none;
	border-top: 1px solid #ccd1d9;
	border-left: none;
}

.tabs-left .nav-tabs > li:first-child,
.tabs-right .nav-tabs > li:first-child {
	border-top: none;
}

.tabs-left .nav-tabs > li > a,
.tabs-right .nav-tabs > li > a {
	min-width: 74px;
	margin-right: 0;
}

.tabs-left .nav-tabs {
	float: left;
	margin-right: 19px;
			border-radius: 0px 0 0 0px;

	-webkit-border-radius: 0px 0 0 0px;
	 -moz-border-radius: 0px 0 0 0px;
}
.tabs-left .nav-tabs > li:first-child > a {
			border-radius: 0px 0 0 0;

	-webkit-border-radius: 0px 0 0 0;
	 -moz-border-radius: 0px 0 0 0;
}
.tabs-left .nav-tabs > li:last-child > a {
			border-radius: 0 0 0 4px;

	-webkit-border-radius: 0 0 0 4px;
	 -moz-border-radius: 0 0 0 4px;
}

.tabs-right .nav-tabs {
	float: right;
	margin-left: 19px;
			border-radius: 0 0px 0px 0;

	-webkit-border-radius: 0 0px 0px 0;
	 -moz-border-radius: 0 0px 0px 0;
}
.tabs-right .nav-tabs > li:first-child > a {
			border-radius: 0 0px 0 0;

	-webkit-border-radius: 0 0px 0 0;
	 -moz-border-radius: 0 0px 0 0;
}
.tabs-right .nav-tabs > li:last-child > a {
			border-radius: 0 0 0px 0;

	-webkit-border-radius: 0 0 0px 0;
	 -moz-border-radius: 0 0 0px 0;
}

/**
 * pill
 * --------------------------------------------------
 */
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
	color: white;
	background-color: #00386d;
}
.nav-pills > li > a {
	color: #00386d;
}
.nav-pills > li > a:hover {
	color: #434a54;
	background-color: #e6e9ed;
}
.nav-pills > .active > a > .badge {
	color: #00386d;
}
.nav-pills .open > a, .nav-pills .open > a:focus, .nav-pills .open > a:hover {
	color: #434a54;
	background-color: #e6e9ed;
}

/**
 * navbar
 * --------------------------------------------------
 */
.navbar-form {
	padding: 0 !important;
}

.navbar-default {
	background-color:	#007dae;
	border-color:	#007dae;
}
.navbar-default .navbar-brand, .navbar-default .navbar-link, .navbar-default .btn-link {
	color: #26816a;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus, .navbar-default .navbar-link:hover, .navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
	color: white;
	background-color: transparent;
}
.navbar-default .navbar-text, .navbar-default .navbar-nav > li > a {
	color: #26816a;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	color: white;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: white;
	background-color: #48cfad;
}
.navbar-default .btn-link[disabled]:hover, fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus, .navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
	color: #2e9c81;
	background-color: transparent;
}
.navbar-default .navbar-toggle {
	background-color: #26816a;
	border-color: #26816a;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #2b957a;
	border-color: #2b957a;
}
.navbar-default .navbar-toggle .icon-bar {
	background-color:	#007dae;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
	border-color: #48cfad;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	color: white;
	background-color:	#007dae;
}
@media (max-width: 767px) {
	.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	background-color: #48cfad;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .divider {
	background-color: #48cfad;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
	color: #26816a;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
	color: white;
	background-color: #48cfad;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .dropdown-header {
	color: #26816a;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
	color: #2b957a;
	}
}

.navbar-inverse {
	background-color: #333;
	border-color: #333;
}
.navbar-inverse .navbar-brand, .navbar-inverse .navbar-link, .navbar-inverse .btn-link {
	color: #8c8c8c;
}
.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-link:hover, .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
	color: white;
	background-color: transparent;
}
.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
	color: #8c8c8c;
}
.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
	color: white;
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
	color: white;
	background-color: black;
}
.navbar-inverse .btn-link[disabled]:hover, fieldset[disabled] .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:focus, .navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
	color: #666;
	background-color: transparent;
}
.navbar-inverse .navbar-toggle {
	background-color: black;
	border-color: black;
}
.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
	background-color: #1a1a1a;
	border-color: #1a1a1a;
}
.navbar-inverse .navbar-toggle .icon-bar {
	background-color: #8c8c8c;
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
	border-color: black;
}
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
	color: white;
	background-color: black;
}
@media (max-width: 767px) {
	.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
	background-color: black;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > .divider {
	background-color: black;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
	color: #8c8c8c;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
	color: white;
	background-color: black;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
	color: #bfbfbf;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
	color: #666;
	}
}

/**
 * list
 * --------------------------------------------------
 */
.list-group {
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.list-group-item {
	border-color: transparent;
	border-top-color: #e6e9ed;
}
.list-group-item:first-child {
	border-top: none;
}
.list-group-item-heading {
	color: #434a54;
}

a.list-group-item {
	color: #434a54;
}
a.list-group-item .list-group-item-heading {
	font-size: 16px;
	color: #434a54;
}
a.list-group-item:hover, a.list-group-item:focus {
	background-color: #e6e9ed;
}
a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
	background-color: #4fc1e9;
	border-color: #4fc1e9;
}
a.list-group-item.active .list-group-item-text, a.list-group-item.active:hover .list-group-item-text, a.list-group-item.active:focus .list-group-item-text {
	color: white;
}

.list-group-item-primary {
	color: #ffffff;
	background-color: #4fc1e9;
	border-color: #00386d transparent transparent transparent;
}
.list-group-item-primary:first-child {
	border-color: transparent;
}
a.list-group-item-primary {
	color: #ffffff;
}
a.list-group-item-primary:hover, a.list-group-item-primary:focus {
	color: white;
	background-color: #00386d;
}
a.list-group-item-primary.active, a.list-group-item-primary:hover, a.list-group-item-primary:focus {
	background-color: #00386d;
	border-color: #4fc1e9 transparent transparent transparent;
}

.list-group-item-success {
	color: #87c940;
	background-color: #a0d468;
	border-color: #4FC2C4 transparent transparent transparent;
}
.list-group-item-success:first-child {
	border-color: transparent;
}
a.list-group-item-success {
	color: #537f24;
}
a.list-group-item-success:hover, a.list-group-item-success:focus {
	color: white;
	background-color: #4FC2C4;
}
a.list-group-item-success.active, a.list-group-item-success:hover, a.list-group-item-success:focus {
	background-color: #4FC2C4;
	border-color: #a0d468 transparent transparent transparent;
}

.list-group-item-warning {
	color: #ffbf21;
	background-color: #ffce54;
	border-color: #FF7D34 transparent transparent transparent;
}
.list-group-item-warning:first-child {
	border-color: transparent;
}
a.list-group-item-warning {
	color: #876000;
}
a.list-group-item-warning:hover, a.list-group-item-warning:focus {
	color: white;
	background-color: #FF7D34;
}
a.list-group-item-warning.active, a.list-group-item-warning:hover, a.list-group-item-warning:focus {
	background-color: #FF7D34;
	border-color: #ffce54 transparent transparent transparent;
}

.list-group-item-info {
	color: #2fb593;
	background-color: #48cfad;
	border-color:	#007dae transparent transparent transparent;
}
.list-group-item-info:first-child {
	border-color: transparent;
}
a.list-group-item-info {
	color: #1a6451;
}
a.list-group-item-info:hover, a.list-group-item-info:focus {
	color: white;
	background-color:	#007dae;
}
a.list-group-item-info.active, a.list-group-item-info:hover, a.list-group-item-info:focus {
	background-color:	#007dae;
	border-color: #48cfad transparent transparent transparent;
}

.list-group-item-danger {
	color: #e8273b;
	background-color: #ed5565;
	border-color: #7D50B2 transparent transparent transparent;
}
.list-group-item-danger:first-child {
	border-color: transparent;
}
a.list-group-item-danger {
	color: #99101f;
}
a.list-group-item-danger:hover, a.list-group-item-danger:focus {
	color: white;
	background-color: #7D50B2;
}
a.list-group-item-danger.active, a.list-group-item-danger:hover, a.list-group-item-danger:focus {
	background-color: #7D50B2;
	border-color: #ed5565 transparent transparent transparent;
}

/**
 * media list
 * --------------------------------------------------
 */
.media-list {
	color: #aab2bd;
}

.media-heading {
	font-size: 14px;
	color: #434a54;
}

/**
 * modal
 * --------------------------------------------------
 */
.modal-content {
	color: #434a54;
	border: none;
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.modal-header {
	border-bottom: none;
}
.modal-body {
	padding: 0 15px;
}
.modal-footer {
	border-top: none;
}

/**
 * well
 * --------------------------------------------------
 */
.well {
	padding: 10px;
	color: #434a54;
	background-color: white;
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.well blockquote {
	border-color: #ccd1d9;
}
.well-lg {
	padding: 20px;
}
.well-sm {
	padding: 5px;
}

/**
 * thumbnail
 * --------------------------------------------------
 */
.thumbnail {
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}
.thumbnail a > img, .thumbnail > img {
	max-width: 100%;
	max-height: 100%;
}
.thumbnail .caption {
	font-size: 14px;
}
.thumbnail .caption h1, .thumbnail .caption h2, .thumbnail .caption h3, .thumbnail .caption h4, .thumbnail .caption h5, .thumbnail .caption h6 {
	margin: 10px 0 10px;
	font-size: 16px;
}

/**
 * jumbotron
 * --------------------------------------------------
 */
.jumbotron {
	padding: 0;
	margin-bottom: 20px;
	background-color: white;
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.container .jumbotron {
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.jumbotron > .jumbotron-photo img {
	width: 100%;
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.jumbotron .jumbotron-contents {
	padding: 20px;
	color: #434a54;
}
.jumbotron .carousel, .jumbotron .carousel-inner, .jumbotron .carousel-inner > .item.active img {
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.jumbotron .carousel-inner > .item > a > img,
.jumbotron .carousel-inner > .item > img {
	width: 100%;
}
.jumbotron .carousel-control.left {
			border-radius: 0px 0 0 0;

	-webkit-border-radius: 0px 0 0 0;
	 -moz-border-radius: 0px 0 0 0;
}
.jumbotron .carousel-control.right {
			border-radius: 0 0px 0 0;

	-webkit-border-radius: 0 0px 0 0;
	 -moz-border-radius: 0 0px 0 0;
}
.jumbotron h1, .jumbotron .h1, .jumbotron h2, .jumbotron .h2 {
	font-weight: 400;
}
.jumbotron h1, .jumbotron .h1 {
	font-size: 28px;
}
.jumbotron h2, .jumbotron .h2 {
	font-size: 24px;
}
.jumbotron p {
	font-size: 14px;
}
@media screen and (min-width: 768px) {
	.jumbotron, .container .jumbotron {
	padding: 0;
	}
	.jumbotron h1, .jumbotron .h1 {
	font-size: 28px;
	}
}

/**
 * panel
 * --------------------------------------------------
 */
.panel {
	background-color: white;
	border: none;
			border-radius: 0px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.panel .list-group {
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;
}
.panel .list-group-item:first-child {
	border-top: 1px solid #e6e9ed;
}
.panel-heading {
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.panel-title {
	font-weight: normal;
	color: #434a54;
}
.panel-footer {
	background-color: #e6e9ed;
	border-top-color: #e6e9ed;
			border-radius: 0 0 4px 4px;

	-webkit-border-radius: 0 0 4px 4px;
	 -moz-border-radius: 0 0 4px 4px;
}
.panel-default {
	border: 1px solid #e6e9ed;
}
.panel-default > .panel-heading {
	color: #434a54;
	background-color: #e6e9ed;
	border-color: #e6e9ed;
}
.panel-primary {
	border: 1px solid #00386d;
}
.panel-primary > .panel-heading {
	color: white;
	background-color: #00386d;
	border-color: #00386d;
}
.panel-success {
	border: 1px solid #4FC2C4;
}
.panel-success > .panel-heading {
	color: white;
	background-color: #4FC2C4;
	border-color: #4FC2C4;
}
.panel-info {
	border: 1px solid	#007dae;
}
.panel-info > .panel-heading {
	color: white;
	background-color:	#007dae;
	border-color:	#007dae;
}
.panel-warning {
	border: 1px solid #FF7D34;
}
.panel-warning > .panel-heading {
	color: white;
	background-color: #FF7D34;
	border-color: #FF7D34;
}
.panel-danger {
	border: 1px solid #7D50B2;
}
.panel-danger > .panel-heading {
	color: white;
	background-color: #7D50B2;
	border-color: #7D50B2;
}
.panel-primary > .panel-heading > .panel-title,
.panel-success > .panel-heading > .panel-title,
.panel-info > .panel-heading > .panel-title,
.panel-warning > .panel-heading > .panel-title,
.panel-danger > .panel-heading > .panel-title,
.panel-primary > .panel-heading > .panel-title a,
.panel-success > .panel-heading > .panel-title a,
.panel-info > .panel-heading > .panel-title a,
.panel-warning > .panel-heading > .panel-title a,
.panel-danger > .panel-heading > .panel-title a {
	color: white;
	font-weight: 400;
}
.panel > .list-group:first-child .list-group-item:first-child, .panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child {
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.panel > .list-group:last-child .list-group-item:last-child {
			border-radius: 0 0 4px 4px;

	-webkit-border-radius: 0 0 4px 4px;
	 -moz-border-radius: 0 0 4px 4px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
			border-radius: 0px 0 0 0;

	-webkit-border-radius: 0px 0 0 0;
	 -moz-border-radius: 0px 0 0 0;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
			border-radius: 0 0px 0 0;

	-webkit-border-radius: 0 0px 0 0;
	 -moz-border-radius: 0 0px 0 0;
}
.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child {
			border-radius: 0 0 4px 4px;

	-webkit-border-radius: 0 0 4px 4px;
	 -moz-border-radius: 0 0 4px 4px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
			border-radius: 0 0 0 4px;

	-webkit-border-radius: 0 0 0 4px;
	 -moz-border-radius: 0 0 0 4px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
			border-radius: 0 0 0px 0;

	-webkit-border-radius: 0 0 0px 0;
	 -moz-border-radius: 0 0 0px 0;
}
.panel > .panel-body + .table, .panel > .panel-body + .table-responsive {
	border-top-color: #e6e9ed;
}

/**
 * accordion
 * --------------------------------------------------
 */
.panel-group .panel {
	background-color: transparent;
			border-radius: 0;
	-webkit-box-shadow: none;
	 -moz-box-shadow: none;
			box-shadow: none;

	-webkit-border-radius: 0;
	 -moz-border-radius: 0;
}
.panel-group .panel + .panel {
	margin-top: 0;
}
.panel-group .panel-heading {
	padding: 0;
	border-bottom-color: transparent;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
	padding: 15px 0;
	border-top-color: transparent;
}
.panel-group .panel-title a {
	display: block;
	padding: 10px 0;
}

.panel-group-lists .panel {
	background-color: white;
	border-bottom: 1px solid #e6e9ed;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
	 -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
			box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}
.panel-group-lists .panel:first-child {
			border-radius: 0px 0px 0 0;

	-webkit-border-radius: 0px 0px 0 0;
	 -moz-border-radius: 0px 0px 0 0;
}
.panel-group-lists .panel:last-child {
	border-bottom: none;
			border-radius: 0 0 4px 4px;

	-webkit-border-radius: 0 0 4px 4px;
	 -moz-border-radius: 0 0 4px 4px;
}
.panel-group-lists .panel-heading + .panel-collapse .panel-body {
	padding: 15px;
	border-top-color: #e6e9ed;
}
.panel-group-lists .panel-title a {
	padding: 10px 15px;
	color: #434a54;
}
.panel-group-lists .panel-title a:hover, .panel-group-lists .panel-title a:focus, .panel-group-lists .panel-title a:active {
	color: #aab2bd;
}



/**
 * timeline
 * --------------------------------------------------
 */
.timeline dl {
	position: relative;
	top: 0;
	padding: 20px 0;
	margin: 0;
}
.timeline dl:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	z-index: 100;
	width: 2px;
	margin-left: -1px;
	content: '';
	background-color: #ccd1d9;
}
.timeline dl dt {
	position: relative;
	top: 30px;
	z-index: 200;
	width: 120px;
	padding: 3px 5px;
	margin: 0 auto 30px;
	font-weight: normal;
	color: white;
	text-align: center;
	background-color: #aab2bd;
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.timeline dl dd {
	position: relative;
	z-index: 200;
}
.timeline dl dd .circ {
	position: absolute;
	top: 40px;
	left: 50%;
	z-index: 200;
	width: 22px;
	height: 22px;
	margin-left: -11px;
	background-color: #4fc1e9;
	border: 4px solid #f5f7fa;
			border-radius: 50%;

	-webkit-border-radius: 50%;
	 -moz-border-radius: 50%;
}
.timeline dl dd .time {
	position: absolute;
	top: 31px;
	left: 50%;
	display: inline-block;
	width: 100px;
	padding: 10px 20px;
	color: #4fc1e9;
}
.timeline dl dd .events {
	position: relative;
	width: 47%;
	padding: 10px 10px 0;
	margin-top: 31px;
	background-color: white;
			border-radius: 0px;

	-webkit-border-radius: 0px;
	 -moz-border-radius: 0px;
}
.timeline dl dd .events:before {
	position: absolute;
	top: 12px;
	width: 0;
	height: 0;
	content: '';
	border-style: solid;
	border-width: 6px;
}
.timeline dl dd .events .events-object {
	margin-right: 10px;
}
.timeline dl dd .events .events-body {
	overflow: hidden;
	zoom: 1;
}
.timeline dl dd .events .events-body .events-heading {
	margin: 0 0 10px;
	font-size: 14px;
}
.timeline dl dd.pos-right .time {
	margin-left: -100px;
	text-align: right;
}
.timeline dl dd.pos-right .events {
	float: right;
}
.timeline dl dd.pos-right .events:before {
	left: -12px;
	border-color: transparent white transparent transparent;
}
.timeline dl dd.pos-left .time {
	margin-left: 0;
	text-align: left;
}
.timeline dl dd.pos-left .events {
	float: left;
}
.timeline dl dd.pos-left .events:before {
	right: -12px;
	border-color: transparent transparent transparent white;
}

@media screen and (max-width: 767px) {
	.timeline dl:before {
	left: 60px;
	}
	.timeline dl dt {
	margin: 0 0 30px;
	}
	.timeline dl dd .circ {
	left: 60px;
	}
	.timeline dl dd .time {
	left: 0;
	}
	.timeline dl dd.pos-left .time {
	padding: 10px 0;
	margin-left: 0;
	text-align: left;
	}
	.timeline dl dd.pos-left .events {
	float: right;
	width: 84%;
	}
	.timeline dl dd.pos-left .events:before {
	left: -12px;
	border-color: transparent white transparent transparent;
	}
	.timeline dl dd.pos-right .time {
	padding: 10px 0;
	margin-left: 0;
	text-align: left;
	}
	.timeline dl dd.pos-right .events {
	float: right;
	width: 84%;
	}
}


/** my style additions **/

.list-group-item:nth-child(even){background-color: #eaf7fd;border-bottom: 4px solid #ffffff; border-top: none;}
.list-group-item:nth-child(odd){background-color: #f1f6f7;border-bottom: 4px solid #ffffff; border-top: none;}


.panel-body ul.list-group {
	margin: 0 -15px -15px;
}
div.panel-body div.panel-body ul.list-group {
	margin: 0 -15px -15px;
}

.webform-progressbar-outer {
	background-color: #e4e4e4;
	border: 0px solid #356900;
	height: 20px;
	margin: 0.35em -1px 30px;
	position: relative;
	width: 100%;
}
.webform-progressbar-inner {
	border-bottom: 0px solid #356900;
	height: 20px;
 background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
 background-size: 40px 40px;
 }

@-webkit-keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}
@-o-keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
	background-position: 40px 0;
	}
	to {
	background-position: 0 0;
	}
}

.webform-progressbar-inner {
	animation: 2s linear 0s normal none infinite running progress-bar-stripes;
}

.webform-progressbar-page .webform-progressbar-page-label {
	font-size: 10px;
	margin: 0 -10em;
	position: relative;
	text-transform: uppercase;
	top: 3px;
}

legend {
	border:none;
}
.indented {margin-left: 10px;}

h3.news-h3 span {
	border-bottom: 1px solid #f5f5f5;
	display: block;
	margin-top: -35px;
	text-transform: capitalize;
}
h3.news-h3 span a {
	padding-right: 15px;
}
.news-list-img {
	display:block;
	align-content: center center;
	overflow:hidden;
	max-width: 225px;
}

.news-content-list {
	overflow:auto;
	clear:both;

	}



.updates-h3 span {
	border-bottom: 1px solid #f5f5f5;
	display: block;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.updates-h3 span a {
	padding-right: 15px;
}
.updates-list-img {
	display:block;
	align-content: center center;
	overflow:hidden;
}

.updates-content-list {
	overflow:auto;
	clear:both;

	}


.views-row-even .news-item-list, .views-row-even .events-item-list, .views-row-even .updates-item-list {
	background-color: #f5f5f5;
	padding: 10px;
}

.views-row-odd .news-item-list, .views-row-odd .events-item-list, .views-row-odd .updates-item-list {
	background-color: #fff;
	padding: 10px;
}

.news-item-list {
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 30px;
}

.events-h3 span {
	display: block;
	margin-bottom: 15px;
	text-transform: capitalize;

}

.events-list-img {
	display:block;
	align-content: center center;
	width:250px;
	overflow:hidden;
}

.events-content-list {
	overflow:auto;
	clear:both;
	}



.events-item-list {
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 25px;
	border-top: 1px solid #f5f5f5;
}

.inPageContent .panel-info .panel-heading a, .inPageContent .panel-info .panel-heading a:link, .inPageContent .panel-info .panel-heading a:active, .inPageContent .panel-info .panel-heading a:hover, .inPageContent .panel-info .panel-heading a:visited {
	color: #fff;
}

.board-list-image {
}

.views-row-even .board-list .panel-info .panel-heading {
	background-color: #4FC2C4;
	border-color: #4FC2C4;
	color: white;
}

.views-row-even .board-list .panel-info {
	border: 1px solid #4FC2C4;
}
.workplace-reps-list .reps-row-tr {
	margin-top: 25px;
}
.workplace-reps-list {width:100%!important;}
.workplace-reps-list .col-1, .workplace-reps-list .col-2, .workplace-reps-list .col-3, .workplace-reps-list .col-4 {
	min-width: 25%!important;
	/*border: 1px dashed green;*/
}
.workplace-reps-list .reps-row {
	margin-top: 25px;
	padding: 0 10px;
	vertical-align: top;
}
.workplace-reps-list .views-view-grid.cols-4 {
	width: 100%;
}

.searchresults-reps-list .reps-row {
	margin-top: 25px;
	padding: 0px;
	vertical-align: top;
}
.searchresults-reps-list .views-view-grid.cols-1 {
	width: 100%;
}

.searchresults-reps-list .views-exposed-form .views-exposed-widget {
	float: left; /* LTR */
	padding: 0em 0em 0 0!important; /* LTR */
}

#views-exposed-form-amapceo-people-view-block-20 #edit-tid-wrapper {
	max-width: 22%;
	float:left;
	margin-right:3%;
}

#views-exposed-form-amapceo-people-view-block-20 #edit-tid-1-wrapper {
	max-width: 22%;
	float:left;
	margin-right:2%;
}
#views-exposed-form-amapceo-people-view-block-20 #edit-tid-2-wrapper {
	max-width: 22%;
	float:left;
	margin-right:2%;
}

#views-exposed-form-amapceo-people-view-block-20 #edit-field-city-value-wrapper {
	max-width: 23%;
	float:left;
}

#views-exposed-form-amapceo-people-view-block-20 #edit-field-city-value-wrapper #edit-field-city-value {
	margin-right: 5px;
	max-width: 100%;
}

#views-exposed-form-amapceo-people-view-block-20 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: .5em 0 0 0;
}

#views-exposed-form-amapceo-people-view-block-20 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 31px;
}

#block-views-amapceo-people-view-block-22 #edit-field-last-name-value {
	max-width: 75%;
	float:left;
	margin-right: 4px;
}

#block-views-amapceo-people-view-block-22 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-22 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0em 0 0 0;
}

#block-views-amapceo-people-view-block-23 #edit-field-last-name-value {
	max-width: 75%;
	float:left;
	margin-right: 4px;
}

#block-views-amapceo-people-view-block-23 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-23 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0em 0 0 0;
}

#block-views-amapceo-people-view-block-25 #edit-tid-1 {
	max-width: 75%;
	float:left;
	margin-right: 4px;
}

#block-views-amapceo-people-view-block-25 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-25 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0em 0 0 0;
}

#block-views-amapceo-people-view-block-24 #edit-field-city-value {
	max-width: 70%;
	float:left;
	margin-right: 4px;
}

#block-views-amapceo-people-view-block-24 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-24 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0em 0 0 0;
}

#block-views-amapceo-people-view-block-27 #edit-field-city-value {
	max-width: 60%;
	float:left;
	margin-right: 4px;
}

#block-views-amapceo-people-view-block-27 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-27 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 0em 0 0 0;
}

#myMembershipInformationSubColumn .view-empty {
background-color: rgba(0, 125, 174, 0.2);
	border: medium none #007dae;
	color: #007dae!important;
	display:block;
	padding:5px;
	text-align: center;
}

#block-views-amapceo-people-view-block-20 .view-empty > p {
background-color: rgba(0, 125, 174, 0.2);
	border: medium none #007dae;
	color: #007dae!important;
	display:block;
	padding:5px;
	text-align: center;
}

#block-views-amapceo-people-view-block-2 .view-empty > p {
background-color: rgba(0, 125, 174, 0.2);
	border: medium none #007dae;
	color: #007dae!important;
	display:block;
	padding:5px;
	text-align: center;
}

.panel .ExpandOMatic {
	margin-left: 10px;
	margin-right: 10px;
}

#block-views-amapceo-people-view-block-2 .views-exposed-widget .views-widget-filter-field_city_value, #views-exposed-form-amapceo-people-view-block-2 .views-exposed-widget.views-widget-filter-field_work_address_value	{
	margin-top: 0px;
}

#views-exposed-form-amapceo-people-view-block-2 .views-exposed-widget.views-widget-filter-field_work_address_value label {
	margin-top: 6px;
}

#block-views-amapceo-people-view-block-2 #edit-field-city-value-wrapper #edit-field-city-value, #block-views-amapceo-people-view-block-2 #edit-field-city-value-wrapper #edit-field-work-address-value	{
	margin-right: 5px;
	max-width: 100%;
}

#block-views-amapceo-people-view-block-2 .views-exposed-form .views-exposed-widget {
	float: none;
	padding: 4px 0;
}

#block-views-amapceo-people-view-block-2 .views-exposed-form .views-exposed-widget select {
	width: 95%;
}

#block-views-amapceo-people-view-block-2 .views-exposed-form .views-exposed-widget .form-submit {
	margin-top: 0px;
}

#block-views-amapceo-people-view-block-26 .searchresults-reps-list .cols-4 {
	width: 100%;
}
#block-views-amapceo-people-view-block-26 .reps-row {
	max-width: 25%;
	min-width: 25%;
}

.date-heading	{
	border: 1px solid #ccc;
	font-weight: normal;
	background-color: rgba(0, 57, 111, 0.2);
	border: medium none #00386d;
	color: #00386d !important;
	padding: 10px;
}
.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next {
	background: none repeat scroll 0 0 transparent;
}

.calendar-calendar .month-view	th.days {
	border: 1px solid #4fc2c4;
	font-weight: normal;
	background-color: rgba(79, 195, 197, 0.2);
	color: #3c9e9f !important;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty, .calendar-calendar .month-view .full td.single-day.empty, .calendar-calendar .month-view .full td.date-box.empty {
	background-color: #e8ebee;
	color: rgba(0, 0, 0, 0.33);
}

.calendar-calendar tr {
	background-color: transparent;
	margin: 0;
	padding: 0;
}

.calendar-calendar .month-view .full tr.single-day .no-entry, .calendar-calendar .month-view .full tr.single-day .no-entry .inner {
	height: 100px !important;
	line-height: 44px;
}
.calendar-calendar .month-view .full tr td.single-day.today {
	border-bottom: 2px solid #7d50b2;
	background-color: rgba(127, 81, 179, 0.2);
	color: #7d50b2 !important;
}
.calendar-calendar .month-view .full td.date-box.today {
	border-color: #7d50b2;
	border-style: solid;
	border-width: 2px 2px 0;
	background-color: rgba(127, 81, 179, 0.2);
	color:	 #7d50b2;
}

.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
	border-left: 2px solid #7d50b2;
	border-right: 2px solid #7d50b2;
	background-color: rgba(127, 81, 179, 0.2);
}

.calendar-calendar .month-view .full td.single-day div.monthview, .calendar-calendar .week-view .full td.single-day div.weekview, .calendar-calendar .day-view .full td.single-day div.dayview {
	overflow: hidden;
	padding: 0 3px;
	background-color: rgba(0, 125, 174, 0.2);
	border: medium none #007dae;
	color: #007dae !important;
	font-size: 16px!important;
	margin:10px;
}

.calendar-calendar td span.date-display-single, .calendar-calendar td span.date-display-start, .calendar-calendar td span.date-display-end, .calendar-calendar td span.date-display-separator {
	font-weight: 300;
}

.calendar-calendar td div.field-content {
	line-height: 1.1em;
}
.calendar-calendar td div.views-field-body div.field-content {
	margin-top: 6px;
}

.month-view h1 {
	margin-bottom: 0px;
	border-bottom: 0px solid #f5f5f5;
	margin-top:10px;
	font-size: 16px;
}

.calendar-calendar .inner p {
	margin: 0;
	padding: 0 0 .25em;
	font-size: 14px;
	line-height: 17px;
	font-weight: 300;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .contents, .calendar-calendar .week-view .full td.multi-day .calendar.weekview .contents {
	background-color: transparent;
	color: #007dae !important;
	font-size: 16px !important;
	margin: 10px;
	overflow: auto;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff, .calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
	padding-left: 0;
	width: 0;
}

.calendar-calendar .month-view .full td.multi-day div.monthview, .calendar-calendar .week-view .full td.multi-day div.weekview, .calendar-calendar .day-view .full td.multi-day div.dayview {
	background: rgba(0, 125, 174, 0.2);
	height: auto;
	margin: 0 0;
	overflow: visible;
	position: relative;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .contents, .calendar-calendar .week-view .full td.multi-day .calendar.weekview .contents {
	height: auto;
	left: 0;
	position: relative;
	width: auto;
}

#webform-client-form-1882 legend, #webform-client-form-1882 label {
	color: #00386d;
}

#webform-client-form-1882 legend {
	margin-top: 20px;
}

#webform-client-form-1882 .form-item {
	margin-bottom: 10px;
	margin-top: 3px;
}

#webform-client-form-1882	.fieldset-wrapper {
	background-color: #e6e9ed;
	color: #00386d;
	padding: 15px;
	overflow: auto;
}

#webform-client-form-1882	.fieldset-wrapper .fieldset-wrapper {
	background-color: #ffffff;
	color: #00386d!important;
	margin-bottom: 20px;
	padding: 25px;
	border-radius: 5px;
}

#webform-client-form-1882	.fieldset-wrapper .fieldset-wrapper .fieldset-wrapper {
	background-color: rgba(0, 125, 174, 0.2);
	color: #007dae!important;
	margin-bottom: 20px;
	padding: 15px;
}

#webform-client-form-1882	.webform-component--your-information--first-name, #webform-client-form-1882	.webform-component--your-information--last-name, #webform-client-form-1882	.webform-component--your-information--amapceo-title, #webform-client-form-1882	.webform-component--your-information--street-address, #webform-client-form-1882	.webform-component--your-information--city, #webform-client-form-1882	.webform-component--your-information--postal-code, #webform-client-form-1882	.webform-component--your-information--home-telephone {
	width: 50%;
	float: left;
}

#webform-client-form-1882 #edit-submitted-your-information-your-email-address {
	background-color: #fff;
	font-size: 14px;
}

#webform-client-form-1882	#edit-submitted-your-information-first-name, #webform-client-form-1882	#edit-submitted-your-information-last-name, #webform-client-form-1882	#edit-submitted-your-information-amapceo-title, #webform-client-form-1882	#edit-submitted-your-information-street-address, #webform-client-form-1882	#edit-submitted-your-information-city, #webform-client-form-1882	#edit-submitted-your-information-postal-code, #webform-client-form-1882	#edit-submitted-your-information-home-telephone, #webform-client-form-1882	.form-textarea {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	display: block;
	font-size: 14px;
	height: 34px;
	line-height: 1.42857;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	border-color: #aab2bd;
	color: #434a54;
}

#webform-client-form-1882	.form-textarea {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	display: block;
	font-size: 14px;
	line-height: 1.42857;
	height:auto;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	border-color: #aab2bd;
	color: #434a54;
}
#webform-client-form-1882	.form-textarea:focus {
	border-color: #3bafda;
}

#webform-client-form-1882	#edit-submitted-your-information-first-name:focus, #webform-client-form-1882	#edit-submitted-your-information-last-name:focus, #webform-client-form-1882	#edit-submitted-your-information-amapceo-title:focus, #webform-client-form-1882	#edit-submitted-your-information-street-address:focus, #webform-client-form-1882	#edit-submitted-your-information-city:focus, #webform-client-form-1882	#edit-submitted-your-information-postal-code:focus, #webform-client-form-1882	#edit-submitted-your-information-home-telephone:focus {
	border-color: #3bafda;
}

#webform-client-form-1882	.fieldset-description {
	margin: 15px 15px 25px 15px;
}

#webform-client-form-1882 .fieldset-legend	{
	background: none no-repeat scroll 5px 65% rgba(0, 0, 0, 0);
	padding-left: 15px;
}

#webform-client-form-1882 .fieldset-legend a	{
	background: url("/misc/menu-expanded.png") no-repeat scroll 5px 65% rgba(0, 0, 0, 0);
	padding-left: 15px;

}
#webform-client-form-1882 .webform-component {
	width: 45%;
	float:left;
}

#webform-client-form-1882 .webform-component-number {
	width: 30%;
	float:left;
	margin-right: 2%;
}

#webform-client-form-1882 .webform-component-date	{
	margin-left:15px;
	max-width:45%;
}

#webform-client-form-1882 .webform-component-textarea	{
	margin-right:45px;
}

#webform-client-form-1882 .webform-component-formula {
	background-color: rgba(0, 57, 111, 0.2);
	color: #00386d !important;
	padding: 10px;
	border-radius: 4px;
	float:left;
	margin-right: 3%;
	width: 30%;
}

#webform-client-form-1882 .webform-datepicker {
	white-space: nowrap;
}

#webform-client-form-1882 .descsription {
	max-width: 75%;
}

#webform-client-form-1882 .webform-component--totaling-your-expenses--less-advance {
	padding: 10px;
	border-radius: 4px;
	float:right;
	margin-right: 4%;
	width: 30%;	background-color: rgba(255, 127, 52, 0.2);
	color: #ff7d34 !important;
	clear:both;
}

#webform-client-form-1882 .webform-component--totaling-your-expenses--claim-total {
	padding: 10px;
	border-radius: 4px;
	float:right;
	margin-right: 4%;
background-color: rgba(79, 195, 197, 0.2);
color: #3c9e9f !important;
	clear:both;
}
#webform-client-form-1882 fieldset {
	clear:both;
}


/*webform 2311*/

#webform-client-form-2311 legend, #webform-client-form-1882 label {
	color: #00386d;
}

#webform-client-form-2311 legend {
	margin-top: 20px;
}


#webform-client-form-2311 .form-item {
	margin-bottom: 10px;
	margin-top: 3px;
}

#webform-client-form-2311	.fieldset-wrapper {
	background-color: #e6e9ed;
	color: #00386d;
	overflow: auto;
}

#webform-client-form-2311	.fieldset-wrapper .fieldset-wrapper {
	background-color: #ffffff;
	color: #00386d!important;
	margin-bottom: 20px;
	padding: 25px;
	border-radius: 5px;

}

#webform-client-form-2311	.fieldset-wrapper .fieldset-wrapper .fieldset-wrapper {
	background-color: rgba(0, 125, 174, 0.2);
	color: #007dae!important;
	margin-bottom: 20px;
	padding: 15px;
}

#webform-client-form-1882	.webform-component--your-information--first-name, #webform-client-form-1882	.webform-component--your-information--last-name, #webform-client-form-1882	.webform-component--your-information--amapceo-title, #webform-client-form-1882	.webform-component--your-information--street-address, #webform-client-form-1882	.webform-component--your-information--city, #webform-client-form-1882	.webform-component--your-information--postal-code, #webform-client-form-1882	.webform-component--your-information--home-telephone {
	width: 50%;
	float: left;
}

#webform-client-form-2311 #edit-submitted-your-information-your-email-address {
	background-color: #fff;
	font-size: 14px;
}

#webform-client-form-1882	#edit-submitted-your-information-first-name, #webform-client-form-1882	#edit-submitted-your-information-last-name, #webform-client-form-1882	#edit-submitted-your-information-amapceo-title, #webform-client-form-1882	#edit-submitted-your-information-street-address, #webform-client-form-1882	#edit-submitted-your-information-city, #webform-client-form-1882	#edit-submitted-your-information-postal-code, #webform-client-form-1882	#edit-submitted-your-information-home-telephone, #webform-client-form-1882	.form-textarea {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	display: block;
	font-size: 14px;
	height: 34px;
	line-height: 1.42857;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	border-color: #aab2bd;
	color: #434a54;
}

#webform-client-form-2311	.form-textarea {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	display: block;
	font-size: 14px;
	line-height: 1.42857;
	height:auto;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	border-color: #aab2bd;
	color: #434a54;
}

#webform-client-form-1882	.form-textarea:focus {
	border-color: #3bafda;
}

#webform-client-form-1882	#edit-submitted-your-information-first-name:focus, #webform-client-form-1882	#edit-submitted-your-information-last-name:focus, #webform-client-form-1882	#edit-submitted-your-information-amapceo-title:focus, #webform-client-form-1882	#edit-submitted-your-information-street-address:focus, #webform-client-form-1882	#edit-submitted-your-information-city:focus, #webform-client-form-1882	#edit-submitted-your-information-postal-code:focus, #webform-client-form-1882	#edit-submitted-your-information-home-telephone:focus {
	border-color: #3bafda;
}

#webform-client-form-2311	.fieldset-description {
	margin: 15px 15px 25px 15px;
}

#webform-client-form-2311 .fieldset-legend	{
	background: none no-repeat scroll 5px 65% rgba(0, 0, 0, 0);
	padding-left: 15px;
}

#webform-client-form-2311 .fieldset-legend a	{

	background: url("/misc/menu-expanded.png") no-repeat scroll 5px 65% rgba(0, 0, 0, 0);
	padding-left: 15px;

}

#webform-client-form-2311 .webform-component {
	width: 45%;
	float:left;
	margin-right: 3%;
}

#webform-client-form-2311 .webform-component-number {
	width: 30%;
	float:left;
	margin-right: 2%;
}

#webform-client-form-2311 .webform-component-date	{
	margin-left:15px;
	max-width:45%;
}

#webform-client-form-2311 .webform-component-textarea	{
	margin-right:45px;
}

#webform-client-form-2311 .webform-component-formula {
	background-color: rgba(0, 57, 111, 0.2);
	color: #00386d !important;
	padding: 10px;
	border-radius: 4px;
	float:left;
	margin-right: 3%;
	width: 30%;
}

#webform-client-form-2311 .webform-datepicker {
	white-space: nowrap;
}

#webform-client-form-2311 .descsription {
	max-width: 75%;
}

#webform-client-form-2311 .webform-component--totaling-your-expenses--less-advance {
	padding: 10px;
	border-radius: 4px;
	float:right;
	margin-right: 4%;
	width: 30%;	background-color: rgba(255, 127, 52, 0.2);
	color: #ff7d34 !important;
	clear:both;
}

#webform-client-form-2311 .webform-component--totaling-your-expenses--claim-total {
	padding: 10px;
	border-radius: 4px;
	float:right;
	margin-right: 4%;
background-color: rgba(79, 195, 197, 0.2);
color: #3c9e9f !important;
	clear:both;
}

#webform-client-form-2311 fieldset {
	clear:both;
}
#webform-client-form-2311 .webform-component--for-which-caucus-are-you-applying, #webform-client-form-2311 .form-actions {
	clear:both;

}

#webform-client-form-2311 .form-actions {
text-align: right;
margin-right: 25%;
}

#webform-client-form-2311 input[type="radio"], #webform-client-form-2311 input[type="checkbox"] {
	line-height: normal;
	margin: -2px 5px 0 10px;
}
