.a-btn-icon {
  background-image: none !important;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
  display: inline-block;
  font-size: 18px !important;
  line-height: 27px;
  padding: 0.5rem 3rem 0.5rem 2rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.a-btn-icon--primary {
  background-color: #00386d;
  border-color: #00386d !important;
  border-radius: 2px;
  color: #ffffff !important;
  text-decoration: none !important;
}

.a-btn-icon--primary:hover {
  background-color: #012446;
}

.a-btn-icon--primary[disabled],
.a-btn-icon--primary.disabled {
  background-color: #00386d !important;
  border-color: #00386d !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--primary[disabled]:hover,
.a-btn-icon--primary.disabled:hover {
  background-color: #00386d !important;
  border-color: #00386d !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--primary i {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  top: 8px;
  right: 1rem;
}

.a-btn-icon--secondary {
  background-color: #0091c0;
  border-color: #0091c0 !important;
  border-radius: 2px;
  color: #ffffff !important;
  text-decoration: none !important;
}

.a-btn-icon--secondary:hover {
  background-color: #027297;
  border-color: #027297 !important;
}

.a-btn-icon--secondary[disabled],
.a-btn-icon--secondary.disabled {
  background-color: #0091c0 !important;
  border-color: #0091c0 !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--secondary[disabled]:hover,
.a-btn-icon--secondary.disabled:hover {
  background-color: #0091c0 !important;
  border-color: #0091c0 !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--secondary i {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  top: 8px;
  right: 1rem;
}

.a-btn-icon--danger {
  background-color: #d20202;
  border-color: #d20202 !important;
  border-radius: 2px;
  color: #ffffff !important;
  text-decoration: none !important;
}

.a-btn-icon--danger:hover {
  background-color: #920202;
}

.a-btn-icon--danger[disabled],
.a-btn-icon--danger.disabled {
  background-color: #920202 !important;
  border-color: #920202 !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--danger[disabled]:hover,
.a-btn-icon--danger.disabled:hover {
  background-color: #920202 !important;
  border-color: #920202 !important;
  color: #ffffff !important;
  opacity: 0.4;
}

.a-btn-icon--danger i {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  top: 8px;
  right: 1rem;
}
