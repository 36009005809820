%l-container--max,
.l-container--max {
  margin: 0 auto;
  max-width: 1150px;
}

%l-container--default,
.l-container--default {
  padding-right: ru(0.5);
  padding-left: ru(0.5);
}

%l-container--medium,
.l-container--medium {
  @extend %l-container--default;

  @include breakpoint($bp-desktop) {
    padding-right: grid-calc(1, 1);
    padding-left: grid-calc(1, 1);
  }

  @include breakpoint($bp-large-wrapper) {
    padding-right: grid-calc(2, 2); // Left padding is 2 column + 2 gutters
    padding-left: grid-calc(2, 2); // Left padding is 2 column + 2 gutters
  }
}

%l-container--lrg,
.l-container--lrg {
  @extend %l-container--default;

  @include breakpoint($bp-large-wrapper) {
    padding-right: grid-calc(1, 1);
    padding-left: grid-calc(1, 1);
  }
}

%l-container--overlap {
  position: relative;
}

.l-container--lrg\:overlap-lrg {
  @extend %l-container--lrg;
  @extend %l-container--overlap;
  top: ru(2);
}

// Container wrappers variation order:
// 1. Vertical margin: --vm-sml
// 2. Vertical padding: --vp-sml
// 3. Colour: --blue
// 4. Overlap: --overlap-sml
//
// Combined variations use a colon (:)
// e.g.
// --vm-sml/:vp-sml/:blue-light

%l-container__wrapper--vm-sml,
.l-container__wrapper--vm-sml {
  margin-top: ru(1);
  margin-bottom: ru(1);
}

%l-container__wrapper--vm-sml-top-no-bottom,
.l-container__wrapper--vm-sml-top-no-bottom {
  margin-top: ru(1);
}

%l-container__wrapper--vp-sml,
.l-container__wrapper--vp-sml {
  padding-top: ru(1);
  padding-bottom: ru(1);
}

%l-container__wrapper--vp-med,
.l-container__wrapper--vp-med {
  padding-top: ru(1);
  padding-bottom: ru(1);
}

%l-container__wrapper--vp-med,
.l-container__wrapper--vp-med {
  padding-top: ru(2);
  padding-bottom: ru(2);
}

%l-container__wrapper--vp-lrg-top-med-bottom,
.l-container__wrapper--vp-lrg-top-med-bottom {
  padding-top: ru(4);
  padding-bottom: ru(2);
}

%l-container__wrapper--overlap,
.l-container__wrapper--overlap {
  position: relative;
}

%l-container__wrapper--blue,
.l-container__wrapper--blue {
  background-color: palette('blue', 400);
}

%l-container__wrapper--blue-light,
.l-container__wrapper--blue-light {
  background-color: palette('blue', 900);
}

.l-container__wrapper--vp-sml\:blue-light {
  @extend %l-container__wrapper--vp-sml;
  @extend %l-container__wrapper--blue-light;
}

.l-container__wrapper--vp-sml\:blue-light\:overlap {
  @extend %l-container__wrapper--vp-sml;
  @extend %l-container__wrapper--blue-light;
  @extend %l-container__wrapper--overlap;
}

.l-container__wrapper--vp-med\:blue {
  @extend %l-container__wrapper--vp-med;
  @extend %l-container__wrapper--blue;
}

.l-container__wrapper--vp-med\:blue-light {
  @extend %l-container__wrapper--vp-med;
  @extend %l-container__wrapper--blue-light;
}

.l-container__wrapper--vp-lrg-top-med-bottom\:blue {
  @extend %l-container__wrapper--vp-lrg-top-med-bottom;
  @extend %l-container__wrapper--blue;
}