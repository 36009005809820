// @todo rework custom grid, no time to code properly.
// @todo rename grid when switch flex box grid from using l-grid name.
.l-gridy__item {
  margin-bottom: ru(1);

  @include breakpoint($bp-2col) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.l-gridy--2col {

  @include breakpoint($bp-2col) {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr ru(2) 1fr;
    grid-template-columns: 1fr ru(2) 1fr;
  }

  > .l-gridy__item:nth-child(1) {

    @include breakpoint($bp-2col) {
      -ms-grid-column: 1; 
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
    }
  }

  > .l-gridy__item:nth-child(2) {

    @include breakpoint($bp-2col) {
      -ms-grid-column: 3; 
      -ms-grid-column-span: 1;
      grid-column: 3 / span 1;
    }
  }
}
