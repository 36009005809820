

.m-menu-main {
  background-color: palette('blue', 250);
  width: 100%;
}

// Menus
%m-menu-main__menu--level-1,
.m-menu-main > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include breakpoint($bp-desktop) {
    display: flex;
    flex-wrap: wrap;
  }
}

%m-menu-main__menu--level-2,
.m-menu-main .level-1 ul {
  margin: 0;
  border-top: 1px solid palette('blue', 400);
  padding: 0;
  list-style-type: none;

  @include breakpoint($bp-desktop) {
    border-top-width: 4px;
  }
}

// Menu items
%m-menu-main__menu-item--level-1,
.m-menu-main .level-1 {

  @include breakpoint($bp-desktop) {
    position: relative;
  }
}

%m-menu-main__menu-item--level-2,
.m-menu-main .level-2 {
  display: block;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: ru(0.5);
    background-color: palette('blue', 900);
    width: calc(100% - #{ru(1)});
    height: 1px;
    content: '';
  }

  &:last-child {

    &:after {
      display: none;
    }
  }
}

// Sub Navs
%m-menu-main__sub-nav,
.m-menu-main .sub-nav {
  display: none;
  box-shadow: 0 12px 40px -20px rgba(0, 0, 0, 0.6);

  @include breakpoint($bp-desktop) {
    position: absolute;
    z-index: 10;
    width: ru(14);
  }

  &.open {
    display: block;
  }
}


// Menu links
%m-menu-main__link--level-1,
.m-menu-main .level-1 > a,
.m-menu-main .level-1 > button {
  display: block;
  border: 0;
  border-bottom: 1px solid palette('white');
  border-radius: 0;
  background-color: palette('blue', 250);
  background-image: none;
  width: 100%;
  padding: ru(0.5) ru(0.5);
  text-transform: uppercase;
  text-align: left;
  line-height: 24px;
  color: palette('white');
  font-size: 18px;
  font-weight: 700;
  transition: background-color 0.35s;
  text-decoration: none;

  @include breakpoint($bp-desktop) {
    border-bottom: 0;
  }

  @include breakpoint($bp-full-nav-lrg) {
    padding-right: ru(0.75);
    padding-left: ru(0.75);
    font-size: 20px;
  }

  &:hover,
  &:active,
  &.open {
    background-color: palette('white');
    color: palette('blue', 250);
  }

  &.active {
    background-color: palette('white');
    color: palette('blue', 250);
  }
}

.m-menu-main .level-1 > button {

  &:after {
    display: inline-block;
    margin-left: ru(0.25);
    content: '+';

    @include breakpoint($bp-desktop) {
      display: none;
    }
  }
}

%m-menu-main__link--level-2,
.m-menu-main .level-2 > a {
  display: block;
  border-left: 4px solid palette('white');
  background-color: palette('white');
  width: 100%;
  padding: ru(0.375) ru(0.5);
  text-transform: uppercase;
  line-height: 24px;
  color: palette('grey', 300);
  font-size: 18px;
  transition: background-color 0.35s;
  text-decoration: none;

  @include breakpoint($bp-full-nav-lrg) {
    padding-left: ru(0.75);
    padding-right: ru(0.75);
    font-size: 20px;
  }

  &:hover,
  &:active {
    border-color: palette('blue', 400);
    background-color: palette('white');
    color: palette('blue', 250);
  }

  &.active {
    color: palette('blue', 250);
  }
}
