a {
  color: palette('blue', 400);
  text-decoration: underline;
}

.a-link--icon {
  display: inline-block;
  // font-size: 18px;
  // line-height: 24px;
  color: palette('blue', 400);

  .g-svg--inline__wrapper {
    display: inline-block;
    position: relative;
    margin-left: ru(0.125);
    width: ru(0.5);
    height: ru(0.5);
    vertical-align: middle;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    color: palette('blue', 250);
  }
}
